.comp1 {
	&__list {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin-top: 10px;
		@include sm(margin-top, 30px); }
	&__item {
		flex-basis: 31%;
		min-width: 350px;
		margin: 15px; }
	&__photo {
		width: 100%;
		height: 300px;
		margin-bottom: 15px;
		clip-path: polygon(0% 5%, 5% 0, 100% 0, 100% 95%, 95% 100%, 0 100%);
		@include sm(height, 300px);
		@include xs(height, 250px);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover; } }
	&__name {
		margin-bottom: 15px;
		font-size: 24px;
		line-height: 1.2;
		color: #262626;
		text-align: center;
		small {
			font-size: 14px;
			display: block;
			padding-bottom: 30px; } }
	&__name {
		&--c {
			color: var(--main-color); } }
	&__desc {
		font-size: 14px;
		color: #828282; }
	&__btn {
		text-align: center;
		margin: 40px 0;
		width: 100%;
		max-width: 300px;
		.btn {
			width: 100%;
			max-width: 300px; }
		@include sm(margin, 20px auto); } }

.comp2 {
	background: var(--main-color) no-repeat 50% 0/cover;
	clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
	&__inner {
		min-height: 500px;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 20px 15px;
		@include sm(min-height, 400px); }
	&--partner {
		min-height: 700px;
		@include sm(padding-top, 50px); }
	&__title {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.9;
		color: #fff;
		margin-bottom: 40px;
		@include sm-block() {
			font-size: 18px;
			line-height: 1.5;
			margin-bottom: 20px; } }
	&__txt {
		color: #F3E1F7;
		line-height: 1.9;
		margin-bottom: 50px;
		max-width: 875px;
		text-align: justify;
		@include md(padding, 0 15px);
		@include sm-block() {
			margin-bottom: 25px;
			padding: 10px; }
		@include xs(padding, 0); }
	&__btn {
		margin-bottom: 60px; } }

.comp3 {
	&__inner {
		padding-bottom: 50px; }
	&__items {
		position: relative;
		display: flex;
		justify-content: center;
		flex-wrap: wrap; }
	&__item {
		// max-width: 415px
		margin: 10px;
		display: flex;
		flex-flow: column wrap;
		@include sm(max-width, 500px); }
	&__img {
		width: 100%;
		// height: 300px
		margin-bottom: 50px;
		@include sm-block() {
			height: 250px; }
		img {
			width: 100%;
			height: 100%;
			object-fit: cover; } }
	&__category {
		text-align: center;
		color: var(--main-color);
		font-weight: 500;
		// margin-bottom: 15px
		margin-top: -30px; }
	&__info {
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		margin-bottom: 10px; }
	&__name {
		flex-basis: 70%;
		color: var(--main-color);
		font-weight: 500;
		font-size: 18px;
		small {
			display: block;
			line-height: 1;
			margin-bottom: 15px;
			font-size: 14px; } }
	&__price {
		color: #FF1CAB;
		white-space: nowrap;
		small {
			font-size: 10px; } }
	&__txt {
		margin-bottom: 20px; }
	&__btn {
		display: flex;
		margin: 0 auto;
		margin-top: auto;
		max-width: 300px;
		width: 100%;
		@include sm-block() {
			margin: 20px auto;
			width: 100%;
			max-width: 300px; } } }
.comp4 {
	&__bg {
		padding: 135px 0;
		background-position: center;
		@include sm-block() {
			background-position-x: 65%;
			background-position-y: 40%;
			margin-top: 25px; } }
	&__inner {
		min-height: 600px;
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-end;
		align-items: center;
		text-align: center;
		@include lg(min-height, 500px);
		@include md(min-height, 400px);
		@include sm(min-height, 300px); }
	&__txt {
		max-width: 580px;
		p {
			line-height: 1.9;
			color: #F3E1F7; } }
	&__btn {
		margin-top: 50px; }
	&--b {
		.title {
			color: #fff;
			&::before {
				line-height: 1.8;
				opacity: 0.2; } }
		.comp4__inner {
			justify-content: flex-start; }
		.comp4__btn {
			margin-top: auto; } } }
.comp5 {
	&__subtitle {
		max-width: 620px;
		margin: 0 auto;
		padding: 15px;
		text-align: center; }
	&__img {
		clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
		@include sm-block() {
				clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
				img {
					min-height: 240px;
					object-fit: cover; } }
		&--r {
			clip-path: polygon(50% 10%, 100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
			@include sm-block() {
				clip-path: polygon(0 45px, 100% 0, 100% calc(100% - 45px), 0 100%);
				img {
					object-fit: cover;
					min-height: 240px; } } } }
	&__items {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 50px;
		margin-top: 20px;
		@include sm(grid-template-columns, 1fr); }
	&__item {
		display: flex;
		flex-flow: column wrap; }
	&__info {
		display: flex;
		flex-flow: column wrap;
		// align-items: center
		// justify-content: space-between
		margin-bottom: 20px;
 }		// margin-left: 20px
	&__name {
		flex-basis: 75%;
		color: var(--main-color);
		font-weight: 500;
		font-size: 18px;
		@include sm(font-size, 24px);
		small {
			display: block;
			line-height: 1;
			font-size: 12px; } }
	&__price {
		color: #FF1CAB;
		white-space: nowrap;
		@include sm(font-size, 18px); }
	&__item-txt2 {
		margin-top: -25px;
		margin-bottom: 20px; }
	&__item-list {
		margin-bottom: 35px;
		@include sm(margin-bottom, 10px);
		li {
			position: relative;
			line-height: 1.9;
			padding-left: 15px;
			// margin-bottom: 20px
			@include sm(margin-bottom, 10px);
			&::before {
				content: "\25c6";
				position: absolute;
				left: 0;
				top: 7px;
				font-size: 12px;
				@include sm-block() {
					top: 5px;
					font-size: 10px; } } } }
	&__btn {
		text-align: center;
		margin-top: auto;
		&--cnt {
			margin-top: 45px; } } }
.comp6 {
	margin-top: 25px;
	&__items {
		position: relative;
		display: flex;
		justify-content: center;
		flex-wrap: wrap; }
	&__item {
		max-width: 415px;
 }		// margin: 5px
	&__figure {
		width: 100%;
		height: 225px;
		clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
		@include sm (height, 112px);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover; } }
	&__info {
		text-align: center;
		color: #525252;
		font-weight: normal;
		margin-top: 12px;
		small {
			display: block;
			font-size: 12px; } } }
.comp7 {
	&--kd {
		.comp7__bg {
			clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
			padding: 50px 0 130px 0;
			background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../img/conference.jpg');
			background-positon: center;
			@include sm-block() {
				clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
				padding: 85px 0;
				background-position-x: 65%; } } }
	&__subtitle {
		max-width: 620px;
		margin: 0 auto;
		padding: 15px;
		text-align: center; }
	&__bg {
		padding: 100px 0;
		clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
		background: linear-gradient(to right, rgba(87, 22, 104, 0.8), rgba(87, 22, 104, 0.8)), url('../img/conference.jpg');
		background-position: center;
		@include sm-block() {
			clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 64px), 50% 100%, 0 calc(100% - 64px));
			padding: 100px 0;
			background-position-x: 65%;
			background-position-y: 20%; } }
	&__inner {
		min-height: 300px;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		text-align: center; }
	&__txt {
		max-width: 620px;
		margin: 0 auto;
		p {
			line-height: 1.9;
			color: #CFDDE2;
			margin-bottom: 30px; } }
	&__btn {
		margin-top: auto;
		text-align: center; } }

.comp8 {
	padding-bottom: 100px;
	&__video {
		position: relative;
		width: 100%;
		min-height: 540px;
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; } }
	&__btn {
		text-align: center;
		margin-top: 45px; } }

.comp9 {
	margin-bottom: 150px;
	@include sm(margin-bottom, 30px);
	&__inner {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		color: #525252;
		@include sm-block() {
			align-items: flex-start; } }
	&__list {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		@include sm(flex-flow, column wrap); }
	&__title {
		margin-bottom: 35px;
		color: #525252;
		@include sm(margin, 0 auto); }
	&__item {
		position: relative;
		counter-increment: number;
		flex-basis: calc(100%/4 - 20px);
		margin: 20px 10px;
		&::before {
			content: counter(number);
			position: absolute;
			top: -35px;
			left: calc(50% - 35px);
			font-size: 72px;
			line-height: 1;
			font-weight: bold;
			color: rgba(87, 22, 104, 0.1);
			@include sm-block() {
				top: -20px;
				left: 20px; } } }
	&__txt {
		max-width: 1040px;
		margin: 0 auto;
		margin-top: 50px; }
	&__txt2 {
		font-weight: bold;
		@include sm(font-weight, normal); }
	&__btn {
		text-align: center;
		margin-top: 60px; }
	&--s3 {
		margin-bottom: 0;
		~ .gallery-page {
			padding-top: 45px; }
		.comp9__item {
			flex-basis: calc(100%/3 - 20px); } } }

.comp10 {
	&__inner {}
	&__list {
		position: relative;
		display: flex;
		height: 360px;
		overflow: hidden;
		@include md(height, 280px);
		@include sm(height, 200px); }
	&__item {
		flex: 0 0 25%;
		&:nth-of-type(1) {
			flex-basis: 50%;
			figure {
				clip-path: polygon(40% 0, 100% 0, 60% 100%, 0% 100%);
				@include sm(clip-path, polygon(0 0, 100% 0, 60% 100%, 0% 100%)); } }
		&:nth-of-type(4) {
			flex-basis: 50%;
			figure {
				clip-path: polygon(40% 0, 100% 0, 60% 100%, 0% 100%);
				@include sm(clip-path, polygon(40% 0, 100% 0, 100% 100%, 0% 100%)); } }
		&:nth-of-type(2),
		&:nth-of-type(3) {
			height: 49.5%;
			position: absolute;
			width: 29%;
			margin: 0 auto;
			right: 0;
			figure {
				clip-path: polygon(34% 0, 100% 0, 66% 100%, 0% 100%); } }
		&:nth-of-type(2) {
			top: 0;
			left: 10%; }
		&:nth-of-type(3) {
			left: -10%;
			bottom: 0; } }
	&__img {
		height: 100%;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover; } } }

.comp10--a {
	@include lg(background, none);
	.comp10 {
		&__list {
			@include md-block() {
				position: relative;
				display: grid;
				grid-template-columns: 1fr 1fr;
				height: 280px;
				// height: 360px
				// overflow: hidden
				// +md(height, 280px)
 } }				// +sm(height, 200px)
		&__item {
			&:nth-of-type(1) {
				@include md(margin-right, -11%);
				figure {
					clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
					@include md(clip-path, polygon(0 0, 99% 0, 50% 100%, 0% 100%)); } }
			&:nth-of-type(2) {
				width: 17%;
				height: 29.72%;
				left: 3.2%;
				@include md(display, none);
				figure {
					clip-path: polygon(43% 0, 100% 0, 61% 100%, 0% 100%); } }
			&:nth-of-type(3) {
				width: 27.7%;
				height: 69%;
				right: 11.1%;
				@include md-block() {
					width: 44.7%;
					height: 60%;
					right: -9%; }
				figure {
					clip-path: polygon(61.5% 0, 100% 0%, 42% 100%, 0% 100%);
					@include md(clip-path, polygon(39% 0, 100% 100%, 2% 100%)); } }
			&:nth-of-type(4) {
				@include md(margin-left, -10.5%);
				figure {
					clip-path: polygon(21% 0, 100% 0, 100% 100%, 26% 100%, 0 47%);
					@include md(clip-path, polygon(19% 0, 100% 0, 100% 100%, 50% 100%, 0 39%)); } } } } }
.comp10--b {
	.comp10 {
		&__list {
			height: 680px;
			@include md(height, 450px);
			@include sm(height, 340px);
			@include xs(height, 240px); }
		&__item {
			&:nth-of-type(1) {
				flex-basis: 60%;
				figure {
					clip-path: polygon(0 0, 100% 0, 50% 59%, 0 21%); } }
			&:nth-of-type(2) {
				width: 64%;
				height: 80.88%;
				left: 36%;
				figure {
					clip-path: polygon(38% 0, 100% 0, 100% 24%, 51% 92%, 0 59%); } }
			&:nth-of-type(3) {
				width: 58.5%;
				height: 51.91%;
				right: 11.8%;
				figure {
					clip-path: polygon(44.5% 0, 100% 51.6%, 75% 100%, 0% 100%); } }
			&:nth-of-type(4) {
				flex-basis: 40%;
				figure {
					clip-path: polygon(66% 44%, 100% 67%, 100% 100%, 13% 100%, 0 90%); } } } } }
