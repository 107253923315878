.contacts {
	margin-top: 56px;
	margin-bottom: 100px;
	@include md(margin-bottom, 80px);
	&__inner {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 15px;
		@include sm-block() {
			gap: 10px; }
		@include xs-block() {
			grid-template-columns: 1fr;
			gap: 80px; } }
	&__info {
		h2 {
			margin-bottom: 32px; }
		&__blocks {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 32px;
			@include md-block() {
				grid-template-columns: 1fr; } }
		&__block {
			h5 {
				font-size: 16px;
				line-height: 24px;
				font-weight: 600;
				margin-bottom: 16px; }
			&__item {
				display: flex;
				gap: 8px;
				align-items: flex-start;
				a {
					color: #000000; }
				&--extra {
					display: flex;
					gap: 8px;
					margin-top: 16px;
					a {
						color: #000000; } } } } }
	&__form {
		background: #EFE8E8;
		max-width: 595px;
		width: 100%;
		padding: 32px;
		position: relative;
		border: 1px solid var(--main-color);
		@include sm-block() {
			padding: 24px;
			max-width: 392px; } } }


