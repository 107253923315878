/*==============================================================
						FOOTER STYLE START */
/*============================================================= */
.footer {
	background: #EFE8E8;
	padding: 23px 0 32px 0;
	//&__inner
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #646464;
		padding-bottom: 30px;
		@include sm-block() {
			flex-direction: column;
			gap: 15px;
			border: none; }
		&__logo {
			flex-shrink: 0;
			width: 280px;
			height: 66px;
			flex-basis: 33.3333%;
			position: relative;
			a {
				display: block;
				img {
					display: block; } }
			@include sm-block() {
				max-width: 200px;
				height: auto; } }
		&__contacts {
			&--item {
				display: flex;
				gap: 8px;
				@include sm-block() {
					justify-content: center; }
				a {
					font-size: 14px;
					color: #646464; }
				&:nth-of-type(1) {
					margin-bottom: 8px; } } }
		&__location {
			flex-basis: 32%;
			display: flex;
			gap: 8px;
			@include sm-block() {
				gap: 0;
				max-width: 350px;
				text-align: center; }
			p {
				font-size: 14px;
				line-height: 20px;
				color: #646464; }

			img {
				width: 20px;
				height: 20px; } }
		&__line {
			display: none;
			@include sm-block() {
				display: block;
				width: 100%;
				height: 1px;
				color: #646464; } } }
	&__bottom {
		padding-top: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include md-block() {
			flex-direction: column;
			gap: 15px; }
		@include sm-block() {
			gap: 24px;
			padding: 0; }
		p {
			font-size: 14px;
			line-height: 20px;
			color: #646464;
			@include md-block() {
				order: 2; } }
		&__nav {
			display: flex;
			justify-content: right;
			@include sm-block() {
				flex-direction: column;
				align-items: center;
				gap: 8px; }
			> li {
				> a {
					display: inline-flex;
					align-items: center;
					padding: 0 10px;
					color: #646464;
					font-size: 14px;
					line-height: 20px;
					font-weight: 400;
					span {
						transition: 0.3s border;
						border-bottom: 1px solid transparent; } }
				&:hover {
					a {
						color: var(--main-color); }
					span {
						border-color: var(--main-color); } }
				.current {
					color: var(--main-color);
					span {
						border-color: var(--main-color); } } } } } }
