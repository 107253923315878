.catalog {
  margin-top: 56px;
  margin-bottom: 100px;
  @include md-block() {
    margin-bottom: 80px;
    margin-top: 40px; }
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @include sm-block() {
      grid-template-columns: 1fr;
      gap: 20px; } }

  &__block {
    padding: 32px;
    background: #EFE8E8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    h2 {
      color: var(--main-color);
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      max-width: 360px;
      margin: 0 auto;
      @include xs-block() {
        font-size: 24px;
        line-height: 32px; } }
    figure {
      width: 100%;
      max-width: 400px;
      height: 350px;
      align-self: center;
      @include md-block() {
        max-width: 328px;
        height: 280px; }
      @include xs-block() {
        max-width: 200px;
        height: 175px; } }
    &__btn {
      display: flex;
      gap: 32px;
      @include md-block() {
        flex-direction: column;
        align-items: center;
        gap: 15px; }
      button, a {
        max-width: 248px;
        @include md-block() {
          max-width: 100%; } } } } }


