/*============= BLOG START ============= */
.blog {
	margin-top: 80px;
	position: relative;
	@include sm-block() {
		margin-top: 0;
		margin-bottom: 10px;
		&::after {
			content: "";
			position: absolute;
			clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 35px), 0 100%);
			width: 100%;
			height: 41%;
			top: -5%;
			background-color: #f5f5fe;
			z-index: -1; } }
	&__filter {
		display: flex;
		flex-wrap: wrap;
		margin: 15px 0;
		margin-left: -15px;
		@include sm-block() {
            flex-wrap: nowrap;
            overflow-x: auto;
            &::-webkit-scrollbar-thumb:horizontal {
                display: none; } }
		li {
			a {
				display: block;
				padding: 10px 15px;
				color: #525252;
				font-size: 18px;
				font-weight: 300;
				line-height: 1.4;
				cursor: pointer;
				user-select: none;
				transition: 0.4s color;
				@include sm(white-space, nowrap);
				&:hover {
					color: var(--main-color); } } } }
	&__articles {
		display: grid;
		grid-row-gap: 80px;
		margin-top: 25px; }
	&-articles {
		&__more {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			&-link {
				color: #828282;
				&::after {
					content: "";
					background: url(../img/elements/arrow--down.svg) no-repeat center/contain;
					width: 10px;
					height: 10px;
					margin-left: 10px;
					display: inline-block; } } } }
	&-article {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-gap: 30px;
		@include sm(grid-template-columns, 1fr);
		&__img {
			width: 100%;
			height: 290px;
			clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		&__info {
			max-width: 750px;
			display: flex;
			flex-flow: column wrap; }
		&__title {
			font-size: 24px;
			font-weight: normal;
			color: #525252; }
		&__txt {
			@include sm(margin-bottom, 5px);
			p {
				padding: 25px 0;
				@include sm(padding, 10px 0); } }
		&__more {
			margin-top: auto;
			@include sm(text-align, right); }
		&__more-link {
			display: inline-flex;
			align-items: center;
			color: #828282;
			filter: contrast(0);
			transition: 0.3s filter;
			&:hover {
				filter: contrast(1); }
			&::after {
				content: "";
				background: url(../img/elements/arrow--right.svg) no-repeat center/contain;
				width: 10px;
				height: 10px;
				margin-left: 6px;
				margin-top: 4px;
				display: inline-block; }
			&:hover {
				&::after {
					transform: rotate(90deg);
					transition: 0.3s; } } } } }
.interview {
	// margin-top: 60px
	// +sm(margin-top, 45px)
	~ .feedback {
		padding-top: 45px;
		margin-top: -50px; }
	.owner {
		padding-bottom: 0px;
		&__info {
			margin-top: 0px; } }
	&-article {
		&__info {
			display: grid;
			grid-template-columns: 2fr 1fr;
			grid-gap: 30px;
			margin-top: 25px;
			margin-bottom: 120px;
			@include sm-block() {
				display: block;
				margin-bottom: 60px; } }
		&__txt {
			display: flex;
			flex-flow: column wrap;
			p {
				margin-bottom: 25px; } }
		&__more {
			text-align: right;
			margin-top: auto;
			&-link {
				display: inline-flex;
				color: #828282;
				&::after {
					content: "";
					background: url(../img/elements/arrow--right.svg) no-repeat center/contain;
					width: 10px;
					height: 10px;
					margin-left: 10px;
					display: inline-block;
					margin-top: 10px; }
				&:hover {
					&::after {
						transform: rotate(90deg);
						transition: 0.3s; } } } }
		&__img {
			width: 100%;
			min-width: 370px;
			height: 290px;
			clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
			@include sm(display, none);
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } }
		&__btn {
			text-align: center;
			margin-top: -80px; } } }
.vsliders {
	clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
	padding: 50px 0 130px 0;
	@include sm-block() {
		clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
		padding: 35px 0 60px 0; } }
.purpose {
	position: relative;
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0 100%);
	margin-top: -80px;
	padding: 90px 0;
	@include sm-block() {
		padding-bottom: 80px;
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 35px), 0 100%); }
	@include xs-block();
	~ {
		.gallery .gallery__articles {
			grid-template-columns: 1fr 1fr; }
		@include sm-block() {
			.gallery .gallery__info {
				flex-flow: column wrap; }
			.gallery .gallery__name {
				order: -1;
				font-size: 24px; } } }
	.interview-article__info {
		margin-bottom: 0;
		color: #525252; }
	.interview-article__btn {
		margin-top: 50px; } }

.main-purpose {
	&__info {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		color: #525252; }
	&__ico {
		margin-bottom: 20px;
		margin-top: 15px; }
	&__subtitle {
		// font-size: 18px
		font-weight: 600;
		text-align: center;
		margin-bottom: 5px;
		@include sm(font-size, 14px);
		span {
			display: block;
			font-weight: normal; } } }

/*============= BLOG END ============= */
