/*==============================================================
						ANIMATION`S START */
/*============================================================= */
@keyframes flipInY {
	0% {
		transform: perspective(400px) rotateY(90deg);
		animation-timing-function: ease-in;
		opacity: 0; }
	40% {
		transform: perspective(400px) rotateY(-20deg);
		animation-timing-function: ease-in; }
	60% {
		transform: perspective(400px) rotateY(10deg);
		opacity: 1; }
	80% {
		transform: perspective(400px) rotateY(-5deg); }
	100% {
		transform: perspective(400px); } }

@keyframes flipInX {
	0% {
		transform: perspective(400px) rotateX(90deg);
		animation-timing-function: ease-in;
		opacity: 0; }
	40% {
		transform: perspective(400px) rotateX(-20deg);
		animation-timing-function: ease-in; }
	60% {
		transform: perspective(400px) rotateX(10deg);
		opacity: 1; }
	80% {
		transform: perspective(400px) rotateX(-5deg); }
	100% {
		transform: perspective(400px); } }

@keyframes fadeIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes arrowDown {
	0% {
		opacity: 0; }
	50% {
		opacity: 1; }
	100% {
		opacity: 0; } }
