.cards {
  margin: 58px 0;
  &__inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    align-content: stretch;
    @include sm-block() {
      grid-template-columns: 1fr 1fr; } } }


.card {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  figure {
    border-bottom: 1px solid #646464;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }
  &__info {
    margin: 15px 0;
    p {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 5px; }
    span {
      color: #646464; } }
  button {
    margin-top: auto;
    max-width: 100%; } }
