:root {
  --main-color: #A30660;
  --lblue: #CFDDE2;
  --secondary-color: #CA91B2;
  --main-font: 'Montserrat', sans-serif;
  --background: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

::placeholder {
  opacity: 1;
  color: #000;
  transition: 0.4s transform, 0.4s opacity;
}

::-moz-selection {
  background: var(--main-color);
  color: #fff;
}

::selection {
  background: var(--main-color);
  color: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 3px;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.65;
  color: #000000;
  font-family: var(--main-font);
  font-weight: 400;
  overflow-x: hidden;
}

a,
button {
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  color: var(--main-color);
  outline: none;
  border: none;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

strong,
b {
  color: #000;
  font-weight: 500;
}

ul {
  list-style: none;
}

input,
textarea,
select {
  background: transparent;
  display: block;
  padding: 5px;
  font-size: 16px;
  font-family: var(--main-font);
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  color: #ffffff;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: var(--lblue);
  opacity: 1;
}

textarea {
  resize: vertical;
  max-height: 200px;
  min-height: 32px;
  height: 32px;
}

input[type='radio'],
input[type='checkbox'],
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  cursor: pointer;
}

select {
  background: url("../img/icons/select-arrow-icon.svg") no-repeat right 10px center/15px;
}

input:focus::placeholder {
  transform: translateX(9px);
  opacity: 0;
}

input[type='checkbox'],
input[type='radio'] {
  background: #fff;
  position: relative;
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 2px solid #000;
  transition: 0.2s;
}

input[type='checkbox'] {
  border: 1px solid #979797;
  width: 20px;
  height: 20px;
}

input[type='checkbox']::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: 2px solid #fff;
  border-top-width: 0;
  border-left-width: 0;
  transform: rotate(45deg) scale(0);
  transition: 0.2s;
}

input[type='checkbox']:checked {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

input[type='checkbox']:checked::before {
  transform: rotate(45deg) scale(1);
}

input[type='radio'] {
  border-radius: 50%;
}

input[type='radio']::before {
  content: "";
  background-color: var(--main-color);
  position: absolute;
  top: 3px;
  left: 3px;
  border: none;
  width: 8px;
  height: 8px;
  border-radius: inherit;
  transform: scale(0);
  transition: 0.2s;
}

input[type='radio']:checked {
  border-color: var(--main-color);
  background-color: #fff;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

.hidden {
  overflow: hidden;
  height: 100dvh;
}

.container {
  width: 100%;
  max-width: 1246px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
}

.progress-container {
  margin-top: 30px;
  height: 4px;
  width: 100%;
  background: #EFE8E8;
  display: none;
}

.progress-bar {
  height: 100%;
  width: 0;
  background-color: var(--main-color);
  transition: width 0.3s ease;
}

/*==============================================================
						HEADER START */

/*============================================================= */

.header {
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 88px;
  z-index: 3;
}

.header__inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header__inner:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 15px;
  border: 1px solid #646464;
}

.header__select {
  background-color: var(--main-color);
  width: 60px;
  height: 32px;
  font-weight: 500;
  border-radius: 4px;
  margin-left: 16px;
}

.header__lside {
  flex: 1;
}

.header__rside {
  flex: 1;
}

.header__rside .header__nav {
  justify-content: flex-end;
}

.header__logo {
  flex-shrink: 0;
  width: 280px;
  height: 66px;
}

.header__logo a {
  display: block;
}

.header__logo a img {
  display: block;
}

.header__nav {
  display: flex;
  justify-content: right;
}

.header__nav > li > a {
  display: inline-flex;
  align-items: center;
  padding: 15px 16px;
  color: #646464;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.header__nav > li > a span {
  transition: 0.3s border;
  border-bottom: 1px solid transparent;
}

.header__nav > li .current {
  color: var(--main-color);
}

.header__nav > li .current span {
  border-color: var(--main-color);
}

.header__nav > li:hover a {
  color: var(--main-color);
}

.header__nav > li:hover span {
  border-color: var(--main-color);
}

.hidden .main {
  position: relative;
  filter: blur(3px);
  opacity: 0.3;
}

.hidden .main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.hidden .footer {
  position: relative;
  filter: blur(3px);
  opacity: 0.3;
}

.hidden .footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.mobile__header {
  display: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #DCCADC;
  font-size: 12px;
  font-weight: 300;
  transition: 0.5s;
}

.mobile__items {
  display: flex;
}

.mobile__select {
  background-color: var(--main-color);
  width: 60px;
  height: 32px;
  font-weight: 500;
  border-radius: 4px;
  margin-left: 16px;
}

.mobile__menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.mobile__menu-burg {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 0;
  margin-left: 24px;
  transition: 0.2s linear;
  z-index: 100;
}

.mobile__menu-burg i {
  top: 50%;
  display: block;
  position: absolute;
  width: inherit;
  height: 3px;
  transition: 0.2s transform;
  background-color: var(--main-color);
}

.mobile__menu-burg i:after,
.mobile__menu-burg i:before {
  content: "";
  position: absolute;
  left: 0;
  width: inherit;
  height: inherit;
  background-color: var(--main-color);
  transition: transform ease 0.15s;
}

.mobile__menu-burg i:before {
  top: -8px;
}

.mobile__menu-burg i:after {
  bottom: -8px;
}

.mobile__menu.active .mobile__menu-burg i {
  background: none;
}

.mobile__menu.active .mobile__menu-burg i:before {
  transform: rotate(45deg);
  top: 0;
  background-color: #ffffff;
}

.mobile__menu.active .mobile__menu-burg i:after {
  transform: rotate(-45deg);
  bottom: 0;
  background-color: #ffffff;
}

.mobile__nav {
  z-index: 1;
  visibility: hidden;
  position: fixed;
  width: 40%;
  height: 100%;
  right: -100%;
  top: 0;
  background: var(--main-color);
  padding: 80px 10px 20px 10px;
  transition: all 0.3s ease 0s;
  overflow: auto;
}

.mobile__nav.active {
  visibility: visible;
  right: 0;
}

.mobile__navcity {
  font-size: 18px;
  letter-spacing: 2rem;
  text-indent: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}

.mobile__navlist {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.mobile__navlist > li > a {
  display: block;
  padding: 8px 0;
  color: #fff;
  font-size: 18px;
}

.mobile__navlist > li > a span {
  transition: 0.3s border;
  border-bottom: 1px solid transparent;
}

.mobile__navlist > li .current {
  color: #EFE8E8;
}

.mobile__navlist > li .current span {
  border-color: #EFE8E8;
}

/*============= BUTTONS STYLE ============= */

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 280px;
  height: 44px;
  color: #fff;
  font-weight: 500;
  line-height: normal;
  font-family: var(--main-font), sans-serif;
  font-size: 16px;
  user-select: none;
  transition: 0.5s;
  background: var(--main-color);
}

.btn--primary {
  background: #ffffff;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  max-width: 280px;
  width: 100%;
  height: 44px;
  transition: 0.5s;
  font-weight: 500;
  line-height: normal;
  font-family: var(--main-font), sans-serif;
  font-size: 16px;
  user-select: none;
  gap: 10px;
}

.btn--primary:hover {
  background: var(--main-color);
  color: #fff;
}

.btn--primary:hover:before {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../img/icons/download-icon-hover.svg") no-repeat center;
}

.btn--primary:before {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../img/icons/download-icon.svg") no-repeat center;
}

/*============= TITLE STYLE ============= */

.title {
  font-size: 52px;
  line-height: 64px;
  font-weight: 600;
  color: var(--main-color);
  text-transform: uppercase;
}

.element-animation {
  opacity: 0;
}

.element-animation.element-show {
  opacity: 1;
  transition: opacity 1s;
}

/*============= NAVIGATION STYLE START ============= */

.navigation {
  margin-top: 30px;
}

.navigation li a {
  display: inline-block;
  padding: 8px;
  font-size: 24px;
  color: var(--main-color);
}

.navigation li a::after,
.navigation li a::before {
  background: var(--main-color);
  width: 4px;
  height: 4px;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(45deg);
  transition: 0.3s;
}

.navigation li a:hover::after,
.navigation li a:hover::before {
  transform: scale(2) rotate(45deg);
}

.navigation--lside li a::after {
  content: "";
  margin-left: 15px;
}

.navigation--rside li a::before {
  content: "";
  margin-right: 15px;
}

/*==============================================================
						COMMON STYLE START */

/*============================================================= */

.main {
  padding-top: 88px;
  min-height: calc(100vh - 220px);
  overflow-x: hidden;
}

/*============= MODIFICATORS ============= */

.--lblue {
  --background: #F1F6F8;
  background: var(--background);
}

.--lpink {
  --background: #FEF5FB;
  background: var(--background);
}

.--dpink {
  --background: rgba(255, 28, 171, 0.08);
  background: var(--background);
}

.--lpurple {
  --background: rgba(245, 245, 254, 1);
  background: var(--background);
}

.--pleft {
  clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 calc(100% - 50px));
  padding: 50px 0 130px 0;
}

.--pright {
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
  padding: 50px 0 100px 0;
}

/*============= Slick slider ============= */

.slick-slider {
  position: relative;
}

.slider__inner {
  position: relative;
}

.slider__inner .slick-dots {
  display: flex;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider__inner .slick-dots li {
  padding: 5px;
  cursor: pointer;
}

.slider__inner .slick-dots li button {
  font-size: 0;
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  background-color: #fff;
  transition: 0.3s all;
}

.slider__inner .slick-dots li.slick-active button {
  transform: scale(1);
  background: var(--main-color);
}

.slider__inner .slick-arrow {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 0;
  width: 40px;
  height: 100%;
  transform: scaleX(0.8);
}

.slider__inner .slick-arrow::before {
  content: "";
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  transition: all 0.3s ease 0s;
}

.slider__inner .slick-arrow:hover::before {
  border: solid var(--main-color);
  border-width: 0 3px 3px 0;
}

.slider__inner .slick-prev {
  left: 5%;
  transform-origin: left;
}

.slider__inner .slick-prev::before {
  transform: rotate(135deg);
  margin-right: -10px;
}

.slider__inner .slick-next {
  right: 5%;
  transform-origin: right;
}

.slider__inner .slick-next::before {
  transform: rotate(-45deg);
  margin-left: -10px;
}

.products__slider {
  position: relative;
}

.products__slider .slick-slide {
  padding: 0 15px;
}

.products__slider .slick-arrow {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 0;
  width: 40px;
  height: 100%;
  transform: scaleX(0.8);
}

.products__slider .slick-arrow::before {
  content: "";
  border: solid #646464;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 10px;
  transition: all 0.3s ease 0s;
}

.products__slider .slick-arrow:hover::before {
  border: solid var(--main-color);
  border-width: 0 2px 2px 0;
}

.products__slider .slick-prev {
  left: 0;
  transform-origin: left;
}

.products__slider .slick-prev::before {
  transform: rotate(135deg);
  margin-right: -10px;
}

.products__slider .slick-next {
  right: 0;
  transform-origin: right;
}

.products__slider .slick-next::before {
  transform: rotate(-45deg);
  margin-left: -10px;
}

.comp3 .slick-arrow {
  height: 50%;
}

.slick-dots {
  position: absolute;
  top: 0;
  display: flex;
  flex-wrap: wrap;
}

.slick-dots li {
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  display: block;
  width: 6px;
  height: 6px;
  border: 1px solid transparent;
  background-color: #C154C1;
  border-radius: 50%;
  transition: 0.3s all;
}

.slick-dots li.slick-active button {
  border-color: var(--dpink);
  transform: scale(2);
  background: #fff;
}

.slick-arrow {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 0;
  width: 50px;
  height: 100%;
  transform: scaleX(0.6);
}

.slick-arrow::before {
  content: "";
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 10px;
}

.slick-prev {
  left: 0;
  transform-origin: left;
}

.slick-prev::before {
  transform: rotate(135deg);
  margin-right: -10px;
}

.slick-next {
  right: 0;
  transform-origin: right;
}

.slick-next::before {
  transform: rotate(-45deg);
  margin-left: -10px;
}

/* SECTIONS SCREEN`S*/

.screen {
  position: relative;
  padding-bottom: 25px;
  overflow: hidden;
  z-index: 2;
}

.screen--white .screen__title {
  color: #fff;
}

.screen--wedding .screen__inner::after {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
}

.screen--wedding .screen__scroll-down i {
  background-image: url("../img/elements/arrow--down-pink.svg");
}

.screen--wedding .screen__border {
  background: #FF1CAB;
}

.screen--events .screen__title {
  letter-spacing: 2vw;
}

.screen--eschool .screen__inner {
  background-position-x: 59%;
}

.screen--eschool .screen__title {
  letter-spacing: 3vw;
}

.screen--eschool .screen__nav::after {
  background-image: linear-gradient(-110deg, rgba(228, 185, 185, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
  width: 64%;
}

.screen--kids .screen__inner {
  background-position-x: 20%;
}

.screen--kids .screen__nav::after {
  width: 60%;
  background-image: linear-gradient(-110deg, rgba(241, 239, 224, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
}

.screen--party .screen__inner::after {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
}

.screen--party .screen__nav::after {
  background-image: linear-gradient(-110deg, rgba(188, 198, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
}

.screen--eshow .screen__nav::after {
  background-image: linear-gradient(-110deg, rgba(188, 198, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
  width: 60%;
}

.screen--product .screen__nav::after {
  background-image: linear-gradient(-110deg, rgba(188, 198, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
  width: 50%;
}

.screen--blog .screen__nav::after {
  background-image: linear-gradient(-110deg, rgba(241, 239, 224, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
  width: 60%;
}

.screen--contacts .screen__subtitle {
  display: none;
  bottom: 45%;
}

.screen--contacts .screen__subtitle a {
  color: #fff;
}

.screen__inner {
  min-height: 950px;
  background: no-repeat center top/cover;
  clip-path: polygon(100% 0, 100% 94%, 50% 100%, 0 94%, 0 0);
}

.screen__content {
  display: flex;
  justify-content: flex-end;
  min-height: inherit;
}

.screen__title {
  position: absolute;
  text-align: center;
  bottom: 40%;
  font-weight: 300;
  font-size: 72px;
  letter-spacing: 3.5vw;
  text-indent: 3.5vw;
  text-align: center;
  left: 0;
  right: 0;
  color: #422362;
  z-index: 2;
  text-transform: uppercase;
  user-select: none;
}

.screen__subtitle {
  position: absolute;
  text-align: center;
  bottom: 35%;
  width: 100%;
  left: 0;
  right: 0;
  font-weight: 300;
  font-size: 24px;
}

.screen__nav {
  width: 390px;
  padding-top: 55px;
  text-align: right;
  z-index: 1;
}

.screen__nav::after {
  content: "";
  position: absolute;
  background-image: linear-gradient(-110deg, rgba(241, 224, 238, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
  width: 45%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}

.screen__scroll-down {
  display: none;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 8px);
  width: 16px;
  height: 20px;
  cursor: pointer;
}

.screen__scroll-down i {
  background: url("../img/elements/arrow--down-white.svg") no-repeat center;
  width: 100%;
  height: 6px;
  margin-top: -2px;
  display: block;
  animation: arrowDown 2s infinite;
}

.screen__scroll-down i:nth-of-type(1) {
  opacity: 0.2;
}

.screen__scroll-down i:nth-of-type(2) {
  opacity: 0.45;
  animation-delay: 0.2s;
}

.screen__scroll-down i:nth-of-type(3) {
  animation-delay: 0.4s;
}

.screen__border {
  background: #0E17F5;
}

.screen__border::before,
.screen__border::after {
  content: "";
  width: 100%;
  height: calc(100% - 20px);
  clip-path: polygon(100% 0, 100% 94%, 50% 100%, 0 94%, 0 0);
  background: #fff;
  display: block;
  position: absolute;
  z-index: -1;
  bottom: 20px;
}

.screen__border::before {
  background: inherit;
  bottom: 19px;
}

.screen__border i {
  position: absolute;
  left: calc(50% - 4px);
  width: 9px;
  height: 9px;
  background: inherit;
  outline: 2px solid #fff;
  transform: rotate(45deg);
}

/*============= HOME - SECTION 1 ============= */

.base {
  background: #F1F6F8;
  position: relative;
  padding-bottom: 25px;
  z-index: 2;
  clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 15px), 0 100%, 0 0);
}

.base__inner {
  min-height: 1000px;
  display: flex;
  clip-path: polygon(100% 0, 100% 94%, 50% 100%, 0 94%, 0 0);
}

.base__part {
  display: flex;
  position: relative;
  background: no-repeat center top/cover;
  min-height: 100%;
  flex: 0 0 50%;
}

.base__part--wedding {
  background-image: url("../img/base__wedding.jpg");
  justify-content: flex-end;
  border-right: 2px solid #fff;
}

.base__part--wedding .base__title {
  right: 0;
  letter-spacing: 0.7em;
}

.base__part--event {
  background-image: url("../img/base__events.jpg");
  border-left: 2px solid #fff;
}

.base__part--event .base__title {
  left: 70px;
  color: #fff;
  letter-spacing: 0.08em;
}

.base__part--event::after {
  transform: scale(-1, 1);
}

.base__title {
  position: absolute;
  bottom: 35%;
  font-weight: 300;
  font-size: 72px;
  color: #422362;
  z-index: 2;
  text-transform: uppercase;
  user-select: none;
}

.base__nav {
  position: relative;
  height: 100%;
  width: 390px;
  padding-top: 55px;
  z-index: 1;
}

.base__nav--lside {
  padding-right: 65px;
  text-align: right;
  background-image: linear-gradient(to left bottom, rgba(241, 224, 238, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
}

.base__nav--rside {
  padding-left: 65px;
  background-image: linear-gradient(to right bottom, rgba(188, 204, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
}

/*============= HOME - SECTION 2 ============= */

.advant {
  position: relative;
}

.advant__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.advant__item {
  flex: 0 1 33.3%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: 60px;
}

.advant__item:hover .advant__ico {
  filter: contrast(1);
}

.advant__ico {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  filter: contrast(0);
  transition: 0.3s filter;
}

.advant__txt {
  font-size: 14px;
  color: #000;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.advant__btn {
  margin-top: auto;
}

/*============= HOME - SECTION 3 ============= */

/*============= HOME - SECTION 4 ============= */

.owner {
  position: relative;
  padding-bottom: 80px;
  margin-top: -50px;
}

.owner__title {
  margin-bottom: 15px;
}

.owner__photo {
  height: 650px;
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
}

.owner__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.owner__info {
  margin-top: 60px;
}

.owner__caption {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  color: #525252;
  margin-bottom: 15px;
}

.owner__contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.owner__contacts .social {
  margin: 25px 0;
}

.owner__contacts .social li {
  width: 30px;
  height: 30px;
}

/*============= WEDDING START ============= */

.organize {
  padding: 0 25px 60px 25px;
}

.organize__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.organize__item {
  flex: 0 1 calc(100%/4 - 20px);
  margin: 10px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.organize__item:hover .organize__ico {
  filter: contrast(1);
}

.organize__ico {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  filter: contrast(0);
  transition: 0.3s filter;
}

.organize__txt {
  color: #525252;
  max-width: 300px;
  margin: 0 auto;
}

.organize__txt p {
  margin-bottom: 35px;
}

.wedding__care {
  margin-bottom: 50px;
}

.wedding__price {
  margin-top: 50px;
}

.wedding__school {
  margin-top: -50px;
}

.wedding__school .comp5__inner {
  padding: 0 25px;
}

.wedding__service {
  margin-top: 80px;
  margin-bottom: 40px;
}

.portfolio {
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
  padding: 50px 0 130px 0;
}

.portfolio .slick-arrow {
  top: initial;
  bottom: 0;
  align-items: flex-end;
}

.portfolio__inner {
  text-align: center;
}

.portfolio__list {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 50px;
}

.portfolio__item {
  max-width: 300px;
  margin: 10px;
}

.portfolio__gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
}

.portfolio__gallery figure {
  overflow: hidden;
}

.portfolio__gallery figure:nth-child(5) {
  grid-row: span 2;
  grid-column: span 2;
}

.portfolio__gallery figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio__gallery-name {
  color: var(--main-color);
  text-align: center;
  padding-top: 10px;
}

.portfolio__gallery-name p {
  font-size: 24px;
  line-height: 1.5;
}

.portfolio__gallery-name small {
  font-size: 16px;
}

.comp1__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 10px;
}

.comp1__item {
  flex-basis: 31%;
  min-width: 350px;
  margin: 15px;
}

.comp1__photo {
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
  clip-path: polygon(0% 5%, 5% 0, 100% 0, 100% 95%, 95% 100%, 0 100%);
}

.comp1__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comp1__name {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 1.2;
  color: #262626;
  text-align: center;
}

.comp1__name small {
  font-size: 14px;
  display: block;
  padding-bottom: 30px;
}

.comp1__name--c {
  color: var(--main-color);
}

.comp1__desc {
  font-size: 14px;
  color: #828282;
}

.comp1__btn {
  text-align: center;
  margin: 40px 0;
  width: 100%;
  max-width: 300px;
}

.comp1__btn .btn {
  width: 100%;
  max-width: 300px;
}

.comp2 {
  background: var(--main-color) no-repeat 50% 0/cover;
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
}

.comp2__inner {
  min-height: 500px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 15px;
}

.comp2--partner {
  min-height: 700px;
}

.comp2__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.9;
  color: #fff;
  margin-bottom: 40px;
}

.comp2__txt {
  color: #F3E1F7;
  line-height: 1.9;
  margin-bottom: 50px;
  max-width: 875px;
  text-align: justify;
}

.comp2__btn {
  margin-bottom: 60px;
}

.comp3__inner {
  padding-bottom: 50px;
}

.comp3__items {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.comp3__item {
  margin: 10px;
  display: flex;
  flex-flow: column wrap;
}

.comp3__img {
  width: 100%;
  margin-bottom: 50px;
}

.comp3__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comp3__category {
  text-align: center;
  color: var(--main-color);
  font-weight: 500;
  margin-top: -30px;
}

.comp3__info {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.comp3__name {
  flex-basis: 70%;
  color: var(--main-color);
  font-weight: 500;
  font-size: 18px;
}

.comp3__name small {
  display: block;
  line-height: 1;
  margin-bottom: 15px;
  font-size: 14px;
}

.comp3__price {
  color: #FF1CAB;
  white-space: nowrap;
}

.comp3__price small {
  font-size: 10px;
}

.comp3__txt {
  margin-bottom: 20px;
}

.comp3__btn {
  display: flex;
  margin: 0 auto;
  margin-top: auto;
  max-width: 300px;
  width: 100%;
}

.comp4__bg {
  padding: 135px 0;
  background-position: center;
}

.comp4__inner {
  min-height: 600px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.comp4__txt {
  max-width: 580px;
}

.comp4__txt p {
  line-height: 1.9;
  color: #F3E1F7;
}

.comp4__btn {
  margin-top: 50px;
}

.comp4--b .title {
  color: #fff;
}

.comp4--b .title::before {
  line-height: 1.8;
  opacity: 0.2;
}

.comp4--b .comp4__inner {
  justify-content: flex-start;
}

.comp4--b .comp4__btn {
  margin-top: auto;
}

.comp5__subtitle {
  max-width: 620px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
}

.comp5__img {
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
}

.comp5__img--r {
  clip-path: polygon(50% 10%, 100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
}

.comp5__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  margin-top: 20px;
}

.comp5__item {
  display: flex;
  flex-flow: column wrap;
}

.comp5__info {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 20px;
}

.comp5__name {
  flex-basis: 75%;
  color: var(--main-color);
  font-weight: 500;
  font-size: 18px;
}

.comp5__name small {
  display: block;
  line-height: 1;
  font-size: 12px;
}

.comp5__price {
  color: #FF1CAB;
  white-space: nowrap;
}

.comp5__item-txt2 {
  margin-top: -25px;
  margin-bottom: 20px;
}

.comp5__item-list {
  margin-bottom: 35px;
}

.comp5__item-list li {
  position: relative;
  line-height: 1.9;
  padding-left: 15px;
}

.comp5__item-list li::before {
  content: "\25c6";
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 12px;
}

.comp5__btn {
  text-align: center;
  margin-top: auto;
}

.comp5__btn--cnt {
  margin-top: 45px;
}

.comp6 {
  margin-top: 25px;
}

.comp6__items {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.comp6__item {
  max-width: 415px;
}

.comp6__figure {
  width: 100%;
  height: 225px;
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
}

.comp6__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comp6__info {
  text-align: center;
  color: #525252;
  font-weight: normal;
  margin-top: 12px;
}

.comp6__info small {
  display: block;
  font-size: 12px;
}

.comp7--kd .comp7__bg {
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
  padding: 50px 0 130px 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../img/conference.jpg");
  background-positon: center;
}

.comp7__subtitle {
  max-width: 620px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
}

.comp7__bg {
  padding: 100px 0;
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
  background: linear-gradient(to right, rgba(87, 22, 104, 0.8), rgba(87, 22, 104, 0.8)), url("../img/conference.jpg");
  background-position: center;
}

.comp7__inner {
  min-height: 300px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  text-align: center;
}

.comp7__txt {
  max-width: 620px;
  margin: 0 auto;
}

.comp7__txt p {
  line-height: 1.9;
  color: #CFDDE2;
  margin-bottom: 30px;
}

.comp7__btn {
  margin-top: auto;
  text-align: center;
}

.comp8 {
  padding-bottom: 100px;
}

.comp8__video {
  position: relative;
  width: 100%;
  min-height: 540px;
}

.comp8__video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.comp8__btn {
  text-align: center;
  margin-top: 45px;
}

.comp9 {
  margin-bottom: 150px;
}

.comp9__inner {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  color: #525252;
}

.comp9__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.comp9__title {
  margin-bottom: 35px;
  color: #525252;
}

.comp9__item {
  position: relative;
  counter-increment: number;
  flex-basis: calc(100%/4 - 20px);
  margin: 20px 10px;
}

.comp9__item::before {
  content: counter(number);
  position: absolute;
  top: -35px;
  left: calc(50% - 35px);
  font-size: 72px;
  line-height: 1;
  font-weight: bold;
  color: rgba(87, 22, 104, 0.1);
}

.comp9__txt {
  max-width: 1040px;
  margin: 0 auto;
  margin-top: 50px;
}

.comp9__txt2 {
  font-weight: bold;
}

.comp9__btn {
  text-align: center;
  margin-top: 60px;
}

.comp9--s3 {
  margin-bottom: 0;
}

.comp9--s3 ~ .gallery-page {
  padding-top: 45px;
}

.comp9--s3 .comp9__item {
  flex-basis: calc(100%/3 - 20px);
}

.comp10__list {
  position: relative;
  display: flex;
  height: 360px;
  overflow: hidden;
}

.comp10__item {
  flex: 0 0 25%;
}

.comp10__item:nth-of-type(1) {
  flex-basis: 50%;
}

.comp10__item:nth-of-type(1) figure {
  clip-path: polygon(40% 0, 100% 0, 60% 100%, 0% 100%);
}

.comp10__item:nth-of-type(4) {
  flex-basis: 50%;
}

.comp10__item:nth-of-type(4) figure {
  clip-path: polygon(40% 0, 100% 0, 60% 100%, 0% 100%);
}

.comp10__item:nth-of-type(2),
.comp10__item:nth-of-type(3) {
  height: 49.5%;
  position: absolute;
  width: 29%;
  margin: 0 auto;
  right: 0;
}

.comp10__item:nth-of-type(2) figure,
.comp10__item:nth-of-type(3) figure {
  clip-path: polygon(34% 0, 100% 0, 66% 100%, 0% 100%);
}

.comp10__item:nth-of-type(2) {
  top: 0;
  left: 10%;
}

.comp10__item:nth-of-type(3) {
  left: -10%;
  bottom: 0;
}

.comp10__img {
  height: 100%;
}

.comp10__img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.comp10--a .comp10__item:nth-of-type(1) figure {
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
}

.comp10--a .comp10__item:nth-of-type(2) {
  width: 17%;
  height: 29.72%;
  left: 3.2%;
}

.comp10--a .comp10__item:nth-of-type(2) figure {
  clip-path: polygon(43% 0, 100% 0, 61% 100%, 0% 100%);
}

.comp10--a .comp10__item:nth-of-type(3) {
  width: 27.7%;
  height: 69%;
  right: 11.1%;
}

.comp10--a .comp10__item:nth-of-type(3) figure {
  clip-path: polygon(61.5% 0, 100% 0%, 42% 100%, 0% 100%);
}

.comp10--a .comp10__item:nth-of-type(4) figure {
  clip-path: polygon(21% 0, 100% 0, 100% 100%, 26% 100%, 0 47%);
}

.comp10--b .comp10__list {
  height: 680px;
}

.comp10--b .comp10__item:nth-of-type(1) {
  flex-basis: 60%;
}

.comp10--b .comp10__item:nth-of-type(1) figure {
  clip-path: polygon(0 0, 100% 0, 50% 59%, 0 21%);
}

.comp10--b .comp10__item:nth-of-type(2) {
  width: 64%;
  height: 80.88%;
  left: 36%;
}

.comp10--b .comp10__item:nth-of-type(2) figure {
  clip-path: polygon(38% 0, 100% 0, 100% 24%, 51% 92%, 0 59%);
}

.comp10--b .comp10__item:nth-of-type(3) {
  width: 58.5%;
  height: 51.91%;
  right: 11.8%;
}

.comp10--b .comp10__item:nth-of-type(3) figure {
  clip-path: polygon(44.5% 0, 100% 51.6%, 75% 100%, 0% 100%);
}

.comp10--b .comp10__item:nth-of-type(4) {
  flex-basis: 40%;
}

.comp10--b .comp10__item:nth-of-type(4) figure {
  clip-path: polygon(66% 44%, 100% 67%, 100% 100%, 13% 100%, 0 90%);
}

.contacts {
  margin-top: 56px;
  margin-bottom: 100px;
}

.contacts__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.contacts__info h2 {
  margin-bottom: 32px;
}

.contacts__info__blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.contacts__info__block h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.contacts__info__block__item {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.contacts__info__block__item a {
  color: #000000;
}

.contacts__info__block__item--extra {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.contacts__info__block__item--extra a {
  color: #000000;
}

.contacts__form {
  background: #EFE8E8;
  max-width: 595px;
  width: 100%;
  padding: 32px;
  position: relative;
  border: 1px solid var(--main-color);
}

/*============= SCHOOL START ============= */

.eschool__bride {
  margin: 70px 0 40px 0;
}

/*============= SCHOOL END ============= */

/*============= BLOG START ============= */

.blog {
  margin-top: 80px;
  position: relative;
}

.blog__filter {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  margin-left: -15px;
}

.blog__filter li a {
  display: block;
  padding: 10px 15px;
  color: #525252;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  cursor: pointer;
  user-select: none;
  transition: 0.4s color;
}

.blog__filter li a:hover {
  color: var(--main-color);
}

.blog__articles {
  display: grid;
  grid-row-gap: 80px;
  margin-top: 25px;
}

.blog-articles__more {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.blog-articles__more-link {
  color: #828282;
}

.blog-articles__more-link::after {
  content: "";
  background: url(../img/elements/arrow--down.svg) no-repeat center/contain;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  display: inline-block;
}

.blog-article {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
}

.blog-article__img {
  width: 100%;
  height: 290px;
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
}

.blog-article__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-article__info {
  max-width: 750px;
  display: flex;
  flex-flow: column wrap;
}

.blog-article__title {
  font-size: 24px;
  font-weight: normal;
  color: #525252;
}

.blog-article__txt p {
  padding: 25px 0;
}

.blog-article__more {
  margin-top: auto;
}

.blog-article__more-link {
  display: inline-flex;
  align-items: center;
  color: #828282;
  filter: contrast(0);
  transition: 0.3s filter;
}

.blog-article__more-link:hover {
  filter: contrast(1);
}

.blog-article__more-link::after {
  content: "";
  background: url(../img/elements/arrow--right.svg) no-repeat center/contain;
  width: 10px;
  height: 10px;
  margin-left: 6px;
  margin-top: 4px;
  display: inline-block;
}

.blog-article__more-link:hover::after {
  transform: rotate(90deg);
  transition: 0.3s;
}

.interview ~ .feedback {
  padding-top: 45px;
  margin-top: -50px;
}

.interview .owner {
  padding-bottom: 0px;
}

.interview .owner__info {
  margin-top: 0px;
}

.interview-article__info {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  margin-top: 25px;
  margin-bottom: 120px;
}

.interview-article__txt {
  display: flex;
  flex-flow: column wrap;
}

.interview-article__txt p {
  margin-bottom: 25px;
}

.interview-article__more {
  text-align: right;
  margin-top: auto;
}

.interview-article__more-link {
  display: inline-flex;
  color: #828282;
}

.interview-article__more-link::after {
  content: "";
  background: url(../img/elements/arrow--right.svg) no-repeat center/contain;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  display: inline-block;
  margin-top: 10px;
}

.interview-article__more-link:hover::after {
  transform: rotate(90deg);
  transition: 0.3s;
}

.interview-article__img {
  width: 100%;
  min-width: 370px;
  height: 290px;
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
}

.interview-article__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.interview-article__btn {
  text-align: center;
  margin-top: -80px;
}

.vsliders {
  clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
  padding: 50px 0 130px 0;
}

.purpose {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0 100%);
  margin-top: -80px;
  padding: 90px 0;
}

.purpose ~ .gallery .gallery__articles {
  grid-template-columns: 1fr 1fr;
}

.purpose .interview-article__info {
  margin-bottom: 0;
  color: #525252;
}

.purpose .interview-article__btn {
  margin-top: 50px;
}

.main-purpose__info {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  color: #525252;
}

.main-purpose__ico {
  margin-bottom: 20px;
  margin-top: 15px;
}

.main-purpose__subtitle {
  font-weight: 600;
  text-align: center;
  margin-bottom: 5px;
}

.main-purpose__subtitle span {
  display: block;
  font-weight: normal;
}

/*============= BLOG END ============= */

/*============= EVENTS__PARTY PAGE ============= */

.events__conference {
  margin-top: 65px;
  margin-bottom: 80px;
}

.events__show-center {
  margin-bottom: -135px;
}

/*============= EVENTS__SHOW PAGE START============= */

.fashion-animals {
  position: relative;
  margin-bottom: -50px;
}

.fashion-animals__subtitle {
  max-width: 620px;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 15px;
  text-align: center;
}

.fashion-animals__btn {
  padding-top: 60px;
  text-align: center;
}

.party__crazy {
  margin-bottom: 95px;
  margin-top: 95px;
}

.eshow {
  position: relative;
  margin-top: -100px;
  margin-bottom: -320px;
  padding-top: 100px;
}

.eshow .comp8 {
  margin-top: 80px;
  margin-bottom: 20px;
}

.eshow__santa {
  margin-top: 100px;
}

.event-top {
  clip-path: polygon(0 0, 100% 0, 100% 69%, 86% 100%, 0 100%);
  margin-top: -90px;
  padding-top: 90px;
}

.event-top__ico img {
  margin: 10px auto;
}

.event-top__subtitle {
  max-width: 620px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 12px;
  text-align: center;
}

.event-top__btn {
  padding: 50px;
  text-align: center;
}

.event-top__btn a {
  margin: 5px 40px;
}

.event-top--r {
  margin-top: 0px;
  clip-path: polygon(24% 0, 100% 0, 100% 69%, 86% 100%, 0 100%, 0 49%);
  padding-top: 10px;
}

.event-top--r ~ .event2 {
  margin-bottom: 70px;
}

.eshow__singing {
  margin-top: -20px;
  margin-bottom: -50px;
}

/*============= EVENTS__SHOW PAGE END============= */

/*============= EVENTS__KIDS PAGE START============= */

.event2 {
  position: relative;
  margin-top: 40px;
  margin-bottom: 100px;
}

.event2__ico img {
  margin: 10px auto;
  margin-top: 7px;
}

.event2__subtitle {
  max-width: 620px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 15px;
  text-align: center;
}

.event2__btn {
  position: absolute;
  bottom: 0;
  right: calc(50% - 125px);
}

.event2__btn .btn {
  width: 250px;
}

.kids-born {
  margin-top: 50px;
}

.kids-aniversary {
  margin-top: 50px;
}

/*============= EVENTS__KIDS PAGE END============= */

/*============= FEEDBACK SECTION ============= */

.feedback__title {
  margin-bottom: 50px;
}

.feedback__form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  align-items: end;
}

.feedback__label {
  display: block;
  color: #525252;
}

.feedback__label--textarea {
  grid-column: 1/-1;
}

.feedback__label--error {
  color: red;
}

.feedback__label--error .feedback__bar {
  background: red;
}

.feedback__label--error .feedback__bar::before,
.feedback__label--error .feedback__bar::after {
  width: 50%;
  background: red;
}

.feedback__field:focus ~ .feedback__bar::before,
.feedback__field:focus ~ .feedback__bar::after {
  width: 50%;
}

.feedback__highlight {
  position: relative;
  user-select: none;
}

.feedback__bar {
  position: relative;
  display: block;
  background: var(--lblue);
  width: 100%;
  height: 1px;
}

.feedback__bar::before,
.feedback__bar::after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: var(--main-color);
  transition: 0.2s width ease;
}

.feedback__bar::before {
  left: 50%;
}

.feedback__bar::after {
  right: 50%;
}

.feedback__btn {
  grid-column: 1/-1;
  justify-self: center;
}

.gallery {
  margin-bottom: -75px;
}

.gallery__inner {
  padding-top: 70px;
  padding-bottom: 180px;
}

.gallery__title {
  color: var(--main-color);
  line-height: 1.8;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}

.gallery__title--page {
  font-weight: 500;
  margin-bottom: 30px;
}

.gallery__ico {
  margin-top: 5px;
  margin-bottom: 30px;
}

.gallery__ico img {
  max-width: 100%;
  display: block;
  height: auto;
  margin: 0 auto;
}

.gallery__filter {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  margin-left: -15px;
}

.gallery__filter li span {
  display: block;
  padding: 10px 15px;
  font-weight: 300;
  line-height: 1.4;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  transition: 0.4s color;
}

.gallery__filter li span:hover {
  color: var(--main-color);
}

.gallery__filter li.active span {
  color: var(--main-color);
}

.gallery__articles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 20px;
  margin-top: 32px;
}

.gallery__article {
  animation: fadeIn 0.5s both;
}

.gallery__img {
  width: 100%;
  height: 290px;
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
  overflow: hidden;
}

.gallery__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s transform;
  will-change: transform;
}

.gallery__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.gallery__count {
  line-height: 1.5;
  color: var(--main-color);
}

.gallery__name {
  color: var(--main-color);
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  flex-shrink: 1;
}

.gallery__price {
  color: #FF1CAB;
  flex-wrap: nowrap;
}

.gallery__price small {
  font-size: 10px;
}

/* Gallery photos */

.gallery__photos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 300px;
  grid-gap: 10px;
}

.gallery__photo {
  overflow: hidden;
}

.gallery__photo:nth-child(10n + 7),
.gallery__photo:nth-child(10n + 8) {
  grid-row: span 2;
  grid-column: span 2;
}

.gallery__photo > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s transform;
}

.gallery__photo:hover > * {
  transform: scale(1.05);
}

/* Gallery page inner */

.gallery-page__inner {
  padding-top: 70px;
  padding-bottom: 210px;
}

.gallery-page__ico {
  margin-top: 15px;
  margin-bottom: 32px;
}

.gallery-page__ico img {
  max-width: 100%;
  display: block;
  height: auto;
  margin: 0 auto;
}

.gallery-page__info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 300px;
  grid-gap: 40px 30px;
  margin-bottom: 40px;
}

.gallery-page__photo {
  grid-row: 1/3;
  height: 640px;
  width: 100%;
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
  overflow: hidden;
}

.gallery-page__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-page__txt {
  line-height: 1.8;
  overflow: hidden;
}

.gallery-page__txt h4 {
  color: #525252;
  font-weight: bold;
}

.gallery-page__txt p {
  font-weight: 300;
  margin-bottom: 20px;
}

.gallery-page__vid {
  height: 300px;
  width: 100%;
  clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
  overflow: hidden;
}

.gallery-page__vid > * {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery-page__btn {
  text-align: center;
  margin-top: 55px;
  margin-bottom: 50px;
}

.page-404 {
  background: url("../img/404.jpg") no-repeat 50% 0/cover;
  min-height: calc(100vh - 55px);
}

.page-404__inner {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-align: center;
}

.page-404__title {
  color: var(--main-color);
  line-height: 2;
  font-size: 96px;
  font-weight: 300;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.page-404__subtitle {
  color: var(--main-color);
  margin-bottom: 45px;
}

.page-404__subtitle h3 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.8;
  margin: 10px;
}

.page-404__subtitle h4 {
  font-weight: 400;
}

.page-404__btn {
  width: 250px;
  text-align: center;
}

/*==============================================================
						FOOTER STYLE START */

/*============================================================= */

.footer {
  background: #EFE8E8;
  padding: 23px 0 32px 0;
}

.footer__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #646464;
  padding-bottom: 30px;
}

.footer__top__logo {
  flex-shrink: 0;
  width: 280px;
  height: 66px;
  flex-basis: 33.3333%;
  position: relative;
}

.footer__top__logo a {
  display: block;
}

.footer__top__logo a img {
  display: block;
}

.footer__top__contacts--item {
  display: flex;
  gap: 8px;
}

.footer__top__contacts--item a {
  font-size: 14px;
  color: #646464;
}

.footer__top__contacts--item:nth-of-type(1) {
  margin-bottom: 8px;
}

.footer__top__location {
  flex-basis: 32%;
  display: flex;
  gap: 8px;
}

.footer__top__location p {
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.footer__top__location img {
  width: 20px;
  height: 20px;
}

.footer__top__line {
  display: none;
}

.footer__bottom {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__bottom p {
  font-size: 14px;
  line-height: 20px;
  color: #646464;
}

.footer__bottom__nav {
  display: flex;
  justify-content: right;
}

.footer__bottom__nav > li > a {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  color: #646464;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.footer__bottom__nav > li > a span {
  transition: 0.3s border;
  border-bottom: 1px solid transparent;
}

.footer__bottom__nav > li:hover a {
  color: var(--main-color);
}

.footer__bottom__nav > li:hover span {
  border-color: var(--main-color);
}

.footer__bottom__nav > li .current {
  color: var(--main-color);
}

.footer__bottom__nav > li .current span {
  border-color: var(--main-color);
}

/*==============================================================
						ANIMATION`S START */

/*============================================================= */

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateY(-20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotateY(10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotateY(-5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotateX(-5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes arrowDown {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */

/* Medium Devices, Desktops */

/* Small Devices, Tablets */

/* Extra Small Devices, Phones */

/* Custom, iPhone Retina */

/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */

/* Extra Small Devices, Phones */

/* Small Devices, Tablets */

/* Medium Devices, Desktops */

/* Large Devices, Wide Screens */

.slider {
  margin-top: 32px;
  margin-bottom: 100px;
}

.slider__block {
  position: relative;
  height: 656px;
}

.slider__block img {
  height: 100%;
  width: 100%;
  position: relative;
}

.slider__block:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 1.3%, rgba(0, 0, 0, 0) 99.58%), linear-gradient(90deg, rgba(163, 6, 96, 0.2) 0%, rgba(163, 6, 96, 0.132) 56.27%, rgba(163, 6, 96, 0.09) 72.95%, rgba(163, 6, 96, 0.06) 88.92%, rgba(163, 6, 96, 0) 100%);
  z-index: 1;
}

.slider__info {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  z-index: 2;
}

.slider__info h1 {
  font-size: 64px;
  font-weight: 600;
  line-height: 72px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.slider__info p {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}

.statistics {
  margin-bottom: 100px;
}

.statistics__inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.statistics__block {
  width: 100%;
  max-width: 280px;
}

.statistics__block:nth-of-type(4) h2 {
  font-size: 100px;
  font-weight: 500;
}

.statistics__block h2 {
  font-size: 64px;
  font-weight: 600;
  line-height: 72px;
  color: var(--main-color);
  border-bottom: 1px solid var(--secondary-color);
  margin-bottom: 5px;
}

.statistics__block p {
  font-size: 24px;
  line-height: 28px;
}

.about {
  margin-bottom: 100px;
}

.about__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.about__inner img {
  width: 100%;
  height: 100%;
}

.about__info {
  padding: 56px;
  background: #EFE8E8;
}

.about__info h2 {
  margin-bottom: 24px;
}

.about__info p {
  line-height: 24px;
}

.about__info p:nth-of-type(1) {
  margin-bottom: 10px;
}

.about__info a {
  margin-top: 30px;
  max-width: 216px;
}

.mainGoal {
  margin-bottom: 100px;
}

.mainGoal__inner h1 {
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 40px;
  text-align: center;
  color: var(--main-color);
}

.glass {
  margin-bottom: 100px;
}

.glass__inner {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: var(--main-color);
  height: 490px;
}

.glass__inner img {
  width: 592px;
  position: absolute;
  left: 0;
}

.glass__info {
  padding: 56px 0 56px 0;
  flex-basis: 50%;
}

.glass__info h2 {
  font-weight: 600;
  font-size: 52px;
  line-height: 64px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.glass__info p {
  color: #ffffff;
}

.glass__info a {
  margin-top: 30px;
  background: transparent;
  border: 1px solid #ffffff;
  max-width: 146px;
}

.products {
  margin-bottom: 100px;
}

.products__inner {
  position: relative;
}

.products__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #646464;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.products__title a {
  max-width: 197px;
}

.products__slider__item {
  background: #ffffff;
  width: 100%;
  max-width: 384px;
}

.products__slider__item figure {
  height: 384px;
  border-bottom: 1px solid #646464;
}

.products__slider__item figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.products__slider__item__bottom {
  margin-top: 15px;
}

.products__slider__item__bottom p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 5px;
}

.products__slider__item__bottom span {
  color: #646464;
}

.products__slider__item__bottom button {
  margin-top: 15px;
  max-width: 100%;
}

.catalog {
  margin-top: 56px;
  margin-bottom: 100px;
}

.catalog__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.catalog__block {
  padding: 32px;
  background: #EFE8E8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.catalog__block h2 {
  color: var(--main-color);
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  max-width: 360px;
  margin: 0 auto;
}

.catalog__block figure {
  width: 100%;
  max-width: 400px;
  height: 350px;
  align-self: center;
}

.catalog__block__btn {
  display: flex;
  gap: 32px;
}

.catalog__block__btn button,
.catalog__block__btn a {
  max-width: 248px;
}

.standard {
  margin-top: 56px;
  margin-bottom: 100px;
}

.standard__title {
  display: flex;
  align-items: center;
}

.standard__title > button {
  display: none;
}

.standard__title h2 {
  font-size: 32px;
  line-height: 40px;
  color: var(--main-color);
  font-weight: 600;
  text-transform: uppercase;
}

.standard__title__btn {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.standard__title__btn button {
  max-width: 100px;
  background: #ffffff;
  border: 1px solid var(--main-color);
  color: #646464;
}

.standard__title__btn .active {
  background: var(--main-color);
  color: #ffffff;
}

.cards {
  margin: 58px 0;
}

.cards__inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  align-content: stretch;
}

.card {
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.card figure {
  border-bottom: 1px solid #646464;
}

.card figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card__info {
  margin: 15px 0;
}

.card__info p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 5px;
}

.card__info span {
  color: #646464;
}

.card button {
  margin-top: auto;
  max-width: 100%;
}

.about-us {
  margin-top: 56px;
  margin-bottom: 100px;
}

.about-us__inner {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.about-us__info p {
  line-height: 24px;
}

.about-us__info p:nth-of-type(1) {
  margin-bottom: 10px;
}

.about-us__info p:nth-of-type(2) {
  margin-bottom: 10px;
}

.why-us {
  margin-bottom: 100px;
}

.why-us__inner {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.why-us__info p {
  line-height: 24px;
}

.why-us__info p:nth-of-type(1) {
  margin-bottom: 10px;
}

.why-us__info p:nth-of-type(2) {
  margin-bottom: 10px;
}

.about-image {
  margin-bottom: 100px;
}

.about-image__block {
  height: fit-content;
}

.about-image__block img {
  width: 100%;
  object-fit: cover;
  height: 440px;
}

.partners {
  margin-bottom: 100px;
}

.partners__inner {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.partners__title {
  max-width: 384px;
}

.partners__info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}

.sustainability {
  margin-top: 56px;
  margin-bottom: 100px;
}

.sustainability__inner {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.sustainability__info p {
  line-height: 24px;
}

.sustainability__info p:nth-of-type(1) {
  margin-bottom: 10px;
}

.sustainability__info p:nth-of-type(2) {
  margin-bottom: 10px;
}

.preserve-taste {
  margin-bottom: 100px;
}

.preserve-taste__block {
  position: relative;
  height: fit-content;
}

.preserve-taste__block img {
  object-fit: cover;
  min-height: 160px;
}

.preserve-taste__info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.preserve-taste__info h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #646464;
}

.important {
  margin-bottom: 100px;
}

.important__inner {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.important__info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.important__block {
  display: flex;
}

.important__block img {
  padding: 2px;
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

.important__block h3 {
  margin: 0;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.formModal {
  display: none;
  position: fixed;
  z-index: 10;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.8);
}

.formModal__inner {
  background: #EFE8E8;
  max-width: 595px;
  width: 100%;
  padding: 32px;
  position: relative;
  border: 1px solid var(--main-color);
  margin: 15px;
}

.formModal__inner svg {
  position: absolute;
  top: -50px;
  right: -50px;
  cursor: pointer;
}

.formModal__back {
  display: none;
}

.contactForm__inner h5 {
  color: var(--main-color);
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-bottom: 24px;
}

.contactForm__block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 24px;
  margin-bottom: 24px;
}

.contactForm__block input {
  height: 44px;
  border: 1px solid #979797;
  background: #ffffff;
  color: #979797;
  padding: 12px;
}

.contactForm__block input::placeholder {
  color: #979797;
}

.contactForm textarea {
  color: #979797;
  background: #ffffff;
  border: 1px solid #979797;
  width: 100%;
  padding: 12px;
  resize: none;
  height: 64px;
  margin-bottom: 24px;
}

.contactForm textarea::placeholder {
  color: #979797;
}

.contactForm__checkbox {
  display: flex;
  justify-content: space-between;
}

.contactForm__checkbox__block {
  display: flex;
  gap: 8px;
}

.contactForm__checkbox button {
  max-width: 144px;
}

.contactForm__checkbox input {
  background: #ffffff;
  width: 20px;
  height: 20px;
}

.contactForm__checkbox p {
  flex: 1;
  font-size: 14px;
  max-width: 324px;
  line-height: 20px;
}

@media only screen and (min-width: 320px) {
  /**/
}

@media only screen and (min-width: 480px) {
  /**/
}

@media only screen and (min-width: 768px) {
  /**/
}

@media only screen and (min-width: 992px) {
  /**/
}

@media only screen and (min-width: 1200px) {
  /**/
}

@media screen and (max-width: 1400px) {
  .base__title {
    font-size: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .base__title {
    font-size: 50px;
  }

  .comp4__inner {
    min-height: 500px;
  }

  .comp10--a {
    background: none;
  }

  .event-top--r {
    margin-top: -50px;
    padding-top: 10px;
  }

  .event2__btn .btn {
    width: 300px;
  }

  .event2__btn {
    position: static;
    text-align: center;
    margin: 30px 0;
  }
}

@media only screen and (max-width: 1200px) {
  /**/
}

@media screen and (max-width: 992px) {
  .progress-container {
    display: block;
  }

  .header__select {
    display: none;
  }

  .header__lside {
    display: none;
  }

  .header__rside {
    display: none;
  }

  .header__logo {
    display: none;
  }

  .mobile__header {
    display: flex;
  }

  .mobile__select {
    display: flex;
  }

  .title {
    font-size: 44px;
    line-height: 52px;
  }

  .navigation li a {
    font-size: 16px;
  }

  .base__inner {
    min-height: 800px;
  }

  .base__title {
    font-size: 40px;
  }

  .advant__list {
    justify-content: space-around;
  }

  .organize__item {
    flex-basis: calc(100%/2 - 10px);
    margin: 0;
  }

  .comp2__txt {
    padding: 0 15px;
  }

  .comp4__inner {
    min-height: 400px;
  }

  .comp10__list {
    height: 280px;
  }

  .comp10--a .comp10__list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 280px;
  }

  .comp10--a .comp10__item:nth-of-type(1) {
    margin-right: -11%;
  }

  .comp10--a .comp10__item:nth-of-type(1) figure {
    clip-path: polygon(0 0, 99% 0, 50% 100%, 0% 100%);
  }

  .comp10--a .comp10__item:nth-of-type(2) {
    display: none;
  }

  .comp10--a .comp10__item:nth-of-type(3) {
    width: 44.7%;
    height: 60%;
    right: -9%;
  }

  .comp10--a .comp10__item:nth-of-type(3) figure {
    clip-path: polygon(39% 0, 100% 100%, 2% 100%);
  }

  .comp10--a .comp10__item:nth-of-type(4) {
    margin-left: -10.5%;
  }

  .comp10--a .comp10__item:nth-of-type(4) figure {
    clip-path: polygon(19% 0, 100% 0, 100% 100%, 50% 100%, 0 39%);
  }

  .comp10--b .comp10__list {
    height: 450px;
  }

  .contacts {
    margin-bottom: 80px;
  }

  .contacts__info__blocks {
    grid-template-columns: 1fr;
  }

  .event-top--r {
    clip-path: polygon(23% 0, 100% 0, 100% 72%, 85% 100%, 0 100%, 0 36%);
  }

  .gallery__photos {
    grid-auto-rows: 200px;
  }

  .footer__bottom {
    flex-direction: column;
    gap: 15px;
  }

  .footer__bottom p {
    order: 2;
  }

  .slider {
    margin-bottom: 80px;
  }

  .slider__info {
    left: 50%;
    text-align: center;
  }

  .statistics {
    margin-bottom: 80px;
  }

  .statistics__inner {
    gap: 20px;
  }

  .statistics__block:nth-of-type(4) h2 {
    font-size: 80px;
  }

  .statistics__block h2 {
    font-size: 44px;
    line-height: 52px;
  }

  .statistics__block p {
    font-size: 16px;
    line-height: 24px;
  }

  .about {
    margin-bottom: 80px;
  }

  .about__inner {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .about__inner img {
    order: 1;
    height: 500px;
  }

  .about__info {
    order: 2;
    padding: 40px 15px;
  }

  .mainGoal {
    margin-bottom: 80px;
  }

  .mainGoal__inner h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .glass {
    margin-bottom: 80px;
  }

  .glass__inner {
    height: 500px;
  }

  .glass__inner img {
    width: 406px;
  }

  .glass__info {
    padding: 40px 0 40px 0;
    align-self: center;
  }

  .glass__info h2 {
    font-size: 44px;
    line-height: 52px;
  }

  .products {
    margin-bottom: 80px;
  }

  .products__slider__item {
    max-width: 256px;
  }

  .products__slider__item figure {
    height: 256px;
  }

  .catalog {
    margin-bottom: 80px;
    margin-top: 40px;
  }

  .catalog__block figure {
    max-width: 328px;
    height: 280px;
  }

  .catalog__block__btn {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .catalog__block__btn button,
  .catalog__block__btn a {
    max-width: 100%;
  }

  .standard {
    margin-bottom: 80px;
    margin-top: 40px;
  }

  .standard__title h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .about-us {
    margin-top: 40px;
  }

  .about-us {
    margin-bottom: 80px;
  }

  .about-us__inner {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .why-us {
    margin-bottom: 80px;
  }

  .why-us__inner {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .about-image {
    margin-bottom: 80px;
  }

  .about-image__block img {
    height: 320px;
  }

  .partners {
    margin-bottom: 80px;
  }

  .partners__inner {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .partners__info {
    gap: 20px;
  }

  .sustainability {
    margin-top: 40px;
  }

  .sustainability {
    margin-bottom: 80px;
  }

  .sustainability__inner {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .preserve-taste {
    margin-bottom: 80px;
  }

  .preserve-taste__info h3 {
    font-size: 16px;
  }

  .important {
    margin-bottom: 80px;
  }

  .important__inner {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .important__info {
    gap: 20px;
  }

  .contactForm__inner h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }

  .contactForm__block {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 992px) {
  /**/
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .header {
    height: 80px;
  }

  .mobile__logo {
    max-width: 200px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
  }

  .navigation li a {
    font-size: 12px;
  }

  .main {
    padding-top: 80px;
  }

  .--pleft {
    clip-path: polygon(0 0, 100% 35px, 100% 100%, 0 calc(100% - 35px));
    padding: 35px 0 60px 0;
  }

  .--pright {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
    padding: 35px 0 60px 0;
  }

  .products__slider .slick-slide {
    padding: 0 5px;
  }

  .screen--white .navigation li a {
    color: #fff;
  }

  .screen--kids .screen__title {
    color: #fff;
  }

  .screen--kids .navigation li a {
    color: #fff;
  }

  .screen--contacts .screen__title {
    color: #fff;
  }

  .screen--contacts .navigation li a {
    color: #fff;
  }

  .screen--contacts .screen__subtitle {
    display: block;
  }

  .screen__inner {
    background-attachment: fixed;
    min-height: calc(100vh - 100px);
    padding-bottom: 50px;
  }

  .screen__inner::after {
    content: "";
    background-image: linear-gradient(to top, var(--main-color) 10%, rgba(255, 255, 255, 0));
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .screen__content {
    flex-direction: column;
  }

  .screen__title {
    bottom: 55%;
    font-size: 32px;
    font-weight: normal;
  }

  .screen__nav {
    text-align: center;
    padding-top: 50%;
    width: 100%;
  }

  .screen__nav .social {
    display: none;
  }

  .screen__nav .navigation li a::after {
    display: none;
  }

  .screen__nav::after {
    display: none;
  }

  .screen__scroll-down {
    display: block;
  }

  .base {
    background: none;
    clip-path: none;
  }

  .base .screen__border::before,
  .base .screen__border::after {
    clip-path: polygon(100% 0, 100% calc(100% - 70px), 50% 100%, 0 calc(100% - 70px), 0 0);
  }

  .base .screen__scroll-down {
    bottom: 40px;
  }

  .base__inner {
    flex-direction: column;
    height: calc(100vh - 40px);
    min-height: 700px;
  }

  .base__part {
    min-height: auto;
    clip-path: polygon(100% 0, 100% calc(100% - 70px), 50% 100%, 0 calc(100% - 70px), 0 0);
    padding-bottom: 0;
    text-align: right;
  }

  .base__part--wedding .base__title {
    letter-spacing: 9vw;
    text-indent: 9vw;
  }

  .base__part--event {
    justify-content: flex-end;
    clip-path: polygon(50% 70px, 100% 0, 100% calc(100% - 70px), 50% 100%, 0% calc(100% - 70px), 0 0);
    margin-top: -60px;
    flex-basis: calc(50% + 60px);
    border-left: none;
  }

  .base__part--event .base__title {
    letter-spacing: 4vw;
    text-indent: 4vw;
    bottom: 20%;
  }

  .base__title {
    font-size: 24px;
    font-weight: normal;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
  }

  .base__nav {
    width: 66.6%;
    padding-right: 10px;
    padding-top: 20px;
  }

  .base__nav .social {
    display: none;
  }

  .base__nav .navigation {
    margin-top: 0;
  }

  .base__nav--lside {
    background-image: linear-gradient(-110deg, rgba(241, 224, 238, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%) !important;
  }

  .base__nav--rside {
    background-image: linear-gradient(-110deg, rgba(188, 204, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%) !important;
  }

  .base__nav--rside {
    padding-left: 0;
  }

  .base__nav--rside {
    padding-top: 50px;
  }

  .base__nav--rside li a {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .base__nav--rside li a::before {
    margin-right: 0;
    margin-left: 15px;
  }

  .advant::after {
    width: 100%;
    height: 60%;
    position: absolute;
    z-index: -1;
    background: #F1F6F8;
    top: -100px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }

  .our-team {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
    padding-top: 50px;
    padding-bottom: 90px;
  }

  .owner {
    padding-bottom: 30px;
  }

  .owner__photo {
    height: 360px;
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
  }

  .organize__txt {
    font-size: 14px;
  }

  .wedding__service {
    margin-bottom: -20px;
  }

  .portfolio {
    padding: 20px 0 60px 0;
  }

  .comp1__list {
    margin-top: 30px;
  }

  .comp1__photo {
    height: 300px;
  }

  .comp1__btn {
    margin: 20px auto;
  }

  .comp2__inner {
    min-height: 400px;
  }

  .comp2--partner {
    padding-top: 50px;
  }

  .comp2__title {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .comp2__txt {
    margin-bottom: 25px;
    padding: 10px;
  }

  .comp3__item {
    max-width: 500px;
  }

  .comp3__img {
    height: 250px;
  }

  .comp3__btn {
    margin: 20px auto;
    width: 100%;
    max-width: 300px;
  }

  .comp4__bg {
    background-position-x: 65%;
    background-position-y: 40%;
    margin-top: 25px;
  }

  .comp4__inner {
    min-height: 300px;
  }

  .comp5__img {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
  }

  .comp5__img img {
    min-height: 240px;
    object-fit: cover;
  }

  .comp5__img--r {
    clip-path: polygon(0 45px, 100% 0, 100% calc(100% - 45px), 0 100%);
  }

  .comp5__img--r img {
    object-fit: cover;
    min-height: 240px;
  }

  .comp5__items {
    grid-template-columns: 1fr;
  }

  .comp5__name {
    font-size: 24px;
  }

  .comp5__price {
    font-size: 18px;
  }

  .comp5__item-list {
    margin-bottom: 10px;
  }

  .comp5__item-list li {
    margin-bottom: 10px;
  }

  .comp5__item-list li::before {
    top: 5px;
    font-size: 10px;
  }

  .comp6__figure {
    height: 112px;
  }

  .comp7--kd .comp7__bg {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
    padding: 85px 0;
    background-position-x: 65%;
  }

  .comp7__bg {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 64px), 50% 100%, 0 calc(100% - 64px));
    padding: 100px 0;
    background-position-x: 65%;
    background-position-y: 20%;
  }

  .comp9 {
    margin-bottom: 30px;
  }

  .comp9__inner {
    align-items: flex-start;
  }

  .comp9__list {
    flex-flow: column wrap;
  }

  .comp9__title {
    margin: 0 auto;
  }

  .comp9__item::before {
    top: -20px;
    left: 20px;
  }

  .comp9__txt2 {
    font-weight: normal;
  }

  .comp10__list {
    height: 200px;
  }

  .comp10__item:nth-of-type(1) figure {
    clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
  }

  .comp10__item:nth-of-type(4) figure {
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
  }

  .comp10--b .comp10__list {
    height: 340px;
  }

  .contacts__inner {
    gap: 10px;
  }

  .contacts__form {
    padding: 24px;
    max-width: 392px;
  }

  .eschool__bride {
    margin-top: 40px;
  }

  .blog {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .blog::after {
    content: "";
    position: absolute;
    clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 35px), 0 100%);
    width: 100%;
    height: 41%;
    top: -5%;
    background-color: #f5f5fe;
    z-index: -1;
  }

  .blog__filter {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .blog__filter::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }

  .blog__filter li a {
    white-space: nowrap;
  }

  .blog-article {
    grid-template-columns: 1fr;
  }

  .blog-article__txt {
    margin-bottom: 5px;
  }

  .blog-article__txt p {
    padding: 10px 0;
  }

  .blog-article__more {
    text-align: right;
  }

  .interview-article__info {
    display: block;
    margin-bottom: 60px;
  }

  .interview-article__img {
    display: none;
  }

  .vsliders {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
    padding: 35px 0 60px 0;
  }

  .purpose {
    padding-bottom: 80px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 35px), 0 100%);
  }

  .purpose ~ .gallery .gallery__info {
    flex-flow: column wrap;
  }

  .purpose ~ .gallery .gallery__name {
    order: -1;
    font-size: 24px;
  }

  .main-purpose__subtitle {
    font-size: 14px;
  }

  .events__conference {
    margin-top: -30px;
    margin-bottom: 0px;
  }

  .events__ceremony {
    margin: 50px 0;
  }

  .events__show-center {
    margin-bottom: -50px;
  }

  .fashion-animals__btn {
    padding-bottom: 30px;
  }

  .party__crazy .comp7__bg {
    clip-path: polygon(50% 8%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
    padding: 80px 0;
  }

  .party__styles {
    margin-bottom: -10px;
  }

  .eshow {
    clip-path: none;
    background: none;
    margin-top: -50px;
  }

  .eshow .comp5__img {
    clip-path: polygon(50% 15%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
  }

  .eshow .comp8__video {
    min-height: 240px;
  }

  .eshow__santa {
    margin-top: 200px;
  }

  .event-top {
    margin-top: -90px;
    clip-path: none;
  }

  .event-top__btn {
    display: flex;
    flex-flow: column wrap;
    flex-basis: 50%;
    align-items: center;
    padding: 40px 0 30px 0;
  }

  .event-top__btn a {
    margin: 0 0 20px 0;
  }

  .event-top--r {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
    padding: 25px 0;
  }

  .event-top--dev {
    padding-bottom: 15px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 35px), 0 100%);
  }

  .eshow__singing {
    margin-top: 0;
    margin-bottom: -35px;
  }

  .eshow__singing .comp7__bg {
    clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
    padding: 80px 0;
  }

  .event2 {
    margin-top: 0px;
    margin-bottom: 120px;
  }

  .event2 .comp10 {
    background: none;
  }

  .feedback__btn {
    margin-top: 25px;
  }

  .gallery {
    margin-bottom: -35px;
  }

  .gallery__inner {
    padding-bottom: 70px;
    padding-top: 20px;
  }

  .gallery__filter {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .gallery__filter::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }

  .gallery__articles {
    grid-template-columns: 1fr;
    margin-top: 0;
  }

  .gallery__name {
    font-size: 14px;
  }

  .gallery__photos {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 29vw;
  }

  .gallery__photo {
    grid-row: auto !important;
    grid-column: auto !important;
  }

  .gallery__photo:nth-child(12n + 4),
  .gallery__photo:nth-child(12n + 11) {
    grid-row: span 2 !important;
    grid-column: span 2 !important;
  }

  .gallery-page::after {
    content: "";
    position: absolute;
    clip-path: polygon(0 40%, 100% 0%, 100% 40%, 0 75%);
    top: 30px;
    width: 100%;
    height: 100%;
    background-color: #F5F5FE;
    z-index: -1;
  }

  .gallery-page__inner {
    padding-bottom: 70px;
    padding-top: 20px;
  }

  .gallery-page__info {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-row-gap: 25px;
    margin-bottom: 10px;
  }

  .gallery-page__photo {
    height: 290px;
  }

  .gallery-page__vid {
    display: none;
  }

  .page-404 {
    min-height: calc(100vh - 40px);
  }

  .page-404__title {
    font-size: 72px;
    line-height: 1.8;
  }

  .page-404__subtitle {
    margin-bottom: 20px;
  }

  .page-404__subtitle h3 {
    font-size: 18px;
  }

  .page-404__subtitle h4 {
    font-size: 14px;
  }

  .footer__top {
    flex-direction: column;
    gap: 15px;
    border: none;
  }

  .footer__top__logo {
    max-width: 200px;
    height: auto;
  }

  .footer__top__contacts--item {
    justify-content: center;
  }

  .footer__top__location {
    gap: 0;
    max-width: 350px;
    text-align: center;
  }

  .footer__top__line {
    display: block;
    width: 100%;
    height: 1px;
    color: #646464;
  }

  .footer__bottom {
    gap: 24px;
    padding: 0;
  }

  .footer__bottom__nav {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .slider__info h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .about__inner img {
    height: 360px;
    object-fit: cover;
  }

  .about__info h2 {
    margin-bottom: 16px;
  }

  .about__info a {
    max-width: 100%;
    margin-top: 24px;
  }

  .glass__inner {
    flex-direction: column;
    height: 600px;
  }

  .glass__inner img {
    top: 17%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 344px;
  }

  .glass__info h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .catalog__inner {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .standard__title {
    flex-direction: column;
    align-items: normal;
    gap: 24px;
  }

  .standard__title h2 {
    text-align: center;
  }

  .standard__title__btn button {
    max-width: 100%;
  }

  .cards__inner {
    grid-template-columns: 1fr 1fr;
  }

  .preserve-taste__info h3 {
    font-size: 14px;
  }

  .preserve-taste__info h3 {
    font-weight: 400;
  }

  .preserve-taste__info .title {
    font-size: 24px;
    line-height: 32px;
  }

  .formModal__inner {
    padding: 24px;
    max-width: 392px;
  }

  .contactForm__checkbox__block {
    margin-bottom: 24px;
  }

  .contactForm__checkbox {
    flex-direction: column;
  }

  .contactForm__checkbox button {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  /**/
}

@media screen and (max-width: 500px) {
  .mobile__nav {
    width: 60%;
  }

  .products__slider .slick-slide {
    padding: 0;
  }

  .products__slider .slick-list {
    padding-left: 0 !important;
  }

  .organize__item {
    flex-basis: 100%;
  }

  .comp1__photo {
    height: 250px;
  }

  .comp2__txt {
    padding: 0;
  }

  .comp10--b .comp10__list {
    height: 240px;
  }

  .contacts__inner {
    grid-template-columns: 1fr;
    gap: 80px;
  }

  .feedback__form {
    grid-template-columns: 1fr;
  }

  .gallery__img {
    height: 112px;
  }

  .slider__block img {
    object-fit: cover;
  }

  .statistics__inner {
    grid-template-columns: repeat(2, 1fr);
  }

  .statistics__block:nth-of-type(4) h2 {
    font-size: 52px;
  }

  .statistics__block h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .about__inner img {
    height: 320px;
  }

  .glass__inner {
    height: 700px;
  }

  .glass__inner img {
    width: 300px;
    top: 14%;
  }

  .glass__info p {
    line-height: 24px;
  }

  .glass__info a {
    max-width: 100%;
  }

  .products__title {
    justify-content: center;
    border: none;
  }

  .products__title a {
    display: none;
  }

  .catalog__block h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .catalog__block figure {
    max-width: 200px;
    height: 175px;
  }

  .standard__title > button {
    display: flex;
    max-width: 100%;
  }

  .about-us {
    margin-top: 24px;
  }

  .partners__info {
    grid-template-columns: repeat(2, 1fr);
  }

  .sustainability {
    margin-top: 24px;
  }

  .preserve-taste__inner {
    padding: 0;
  }

  .important__info {
    grid-template-columns: repeat(1, 1fr);
  }

  .important__block img {
    width: 48px;
    height: 48px;
  }

  .formModal {
    background: #ffffff;
    padding: 10px;
  }

  .formModal__inner svg {
    display: none;
  }

  .formModal__back {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #646464;
    width: 100%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 480px) {
  /**/
}

@media only screen and (max-width: 320px) {
  /**/
}