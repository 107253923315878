.slider {
  margin-top: 32px;
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);
  &__block {
    position: relative;
    height: 656px;
    img {
      height: 100%;
      width: 100%;
      position: relative;
      @include xs(object-fit, cover); }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 1.3%, rgba(0, 0, 0, 0) 99.58%), linear-gradient(90deg, rgba(163, 6, 96, 0.2) 0%, rgba(163, 6, 96, 0.132) 56.27%, rgba(163, 6, 96, 0.09) 72.95%, rgba(163, 6, 96, 0.06) 88.92%, rgba(163, 6, 96, 0) 100%);
      z-index: 1; } }

  &__info {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    z-index: 2;
    @include md-block() {
        left: 50%;
        text-align: center; }
    h1 {
      font-size: 64px;
      font-weight: 600;
      line-height: 72px;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include sm-block() {
          font-size: 32px;
          line-height: 40px; } }
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 24px; } } }
.statistics {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);
  &__inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    @include md(gap, 20px);
    @include xs-block() {
        grid-template-columns: repeat(2, 1fr); } }

  &__block {
    width: 100%;
    max-width: 280px;
    &:nth-of-type(4) {
      h2 {
        font-size: 100px;
        font-weight: 500;
        @include md(font-size, 80px);
        @include xs(font-size, 52px); } }
    h2 {
      font-size: 64px;
      font-weight: 600;
      line-height: 72px;
      color: var(--main-color);
      border-bottom: 1px solid var(--secondary-color);
      margin-bottom: 5px;
      @include md-block() {
          font-size: 44px;
          line-height: 52px; }
      @include xs-block() {
          font-size: 32px;
          line-height: 40px; } }
    p {
      font-size: 24px;
      line-height: 28px;
      @include md-block() {
        font-size: 16px;
        line-height: 24px; } } } }
.about {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include md-block() {
      grid-template-columns: 1fr;
      padding: 0; }
    img {
      width: 100%;
      height: 100%;
      @include md-block() {
        order: 1;
        height: 500px; }
      @include sm-block() {
        height: 360px;
        object-fit: cover; }
      @include xs-block() {
        height: 320px; } } }
  &__info {
    padding: 56px;
    background: #EFE8E8;
    @include md-block() {
      order: 2;
      padding: 40px 15px; }
    h2 {
      margin-bottom: 24px;
      @include sm-block() {
        margin-bottom: 16px; } }
    p {
      line-height: 24px;
      &:nth-of-type(1) {
        margin-bottom: 10px; } }
    a {
      margin-top: 30px;
      max-width: 216px;
      @include sm-block() {
        max-width: 100%;
        margin-top: 24px; } } } }
.mainGoal {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);
  &__inner {
    h1 {
      font-weight: 600;
      font-size: 32px;
      text-transform: uppercase;
      line-height: 40px;
      text-align: center;
      color: var(--main-color);
      @include md-block() {
        font-size: 24px;
        line-height: 32px; } } } }
.glass {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);
  &__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background: var(--main-color);
    height: 490px;
    @include md-block() {
      height: 500px; }
    @include sm-block() {
      flex-direction: column;
      height: 600px; }
    @include xs-block() {
      height: 700px; }
    img {
      width: 592px;
      position: absolute;
      left: 0;
      @include md-block() {
        width: 406px; }
      @include sm-block() {
        top: 17%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 344px; }
      @include xs-block() {
        width: 300px;
        top: 14%; } } }
  &__info {
    padding: 56px 0 56px 0;
    flex-basis: 50%;
    @include md-block() {
      padding: 40px 0 40px 0;
      align-self: center; }
    h2 {
      font-weight: 600;
      font-size: 52px;
      line-height: 64px;
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 24px;
      @include md-block() {
        font-size: 44px;
        line-height: 52px; }
      @include sm-block() {
        font-size: 32px;
        line-height: 40px; } }
    p {
      color: #ffffff;
      @include xs(line-height, 24px); }
    a {
      margin-top: 30px;
      background: transparent;
      border: 1px solid #ffffff;
      max-width: 146px;
      @include xs-block() {
        max-width: 100%; } } } }
.products {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);
  &__inner {
    position: relative; }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #646464;
    padding-bottom: 10px;
    margin-bottom: 20px;
    @include xs-block() {
      justify-content: center;
      border: none; }
    a {
      max-width: 197px;
      @include xs(display, none); } }
  &__slider {
    &__item {
      background: #ffffff;
      width: 100%;
      max-width: 384px;
      @include md-block() {
        max-width: 256px; }
      figure {
        height: 384px;
        border-bottom: 1px solid #646464;
        @include md-block() {
          height: 256px; }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain; } }
      &__bottom {
        margin-top: 15px;
        p {
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;

          margin-bottom: 5px; }
        span {
          color: #646464; }
        button {
          margin-top: 15px;
          max-width: 100%; } } } } }
