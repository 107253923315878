/*============= BUTTONS STYLE ============= */
.btn {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 280px;
	height: 44px;
	color: #fff;
	font-weight: 500;
	line-height: normal;
	font-family: var(--main-font), sans-serif;
	font-size: 16px;
	user-select: none;
	transition: 0.5s;
	background: var(--main-color);
	&--primary {
		//clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px)
		background: #ffffff;
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--main-color);
		color: var(--main-color);
		max-width: 280px;
		width: 100%;
		height: 44px;
		transition: 0.5s;
		font-weight: 500;
		line-height: normal;
		font-family: var(--main-font), sans-serif;
		font-size: 16px;
		user-select: none;
		gap: 10px;
		&:hover {
			background: var(--main-color);
			color: #fff;
			&:before {
				content: "";
				width: 24px;
				height: 24px;
				background: url('../img/icons/download-icon-hover.svg') no-repeat center; } }
		&:before {
			content: "";
			width: 24px;
			height: 24px;
			background: url('../img/icons/download-icon.svg') no-repeat center; } } }


/*============= TITLE STYLE ============= */
.title {
	font-size: 52px;
	line-height: 64px;
	font-weight: 600;
	color: var(--main-color);
	text-transform: uppercase;
	@include md-block() {
		font-size: 44px;
		line-height: 52px; }
	@include sm-block() {
		font-size: 32px;
		line-height: 40px; } }


.element-animation {
	opacity: 0;
	&.element-show {
		opacity: 1;
		transition: opacity 1s; } }
/*============= NAVIGATION STYLE START ============= */
.navigation {
	margin-top: 30px;
	li {
		a {
			display: inline-block;
			padding: 8px;
			font-size: 24px;
			color: var(--main-color);
			@include md(font-size, 16px);
			@include sm(font-size, 12px);
			&::after, &::before {
				background: var(--main-color);
				width: 4px;
				height: 4px;
				display: inline-block;
				vertical-align: middle;
				transform: rotate(45deg);
				transition: 0.3s; }
			&:hover {
				&::after, &::before {
					transform: scale(2) rotate(45deg); } } } }
	&--lside {
		li {
			a {
				&::after {
					content: "";
					margin-left: 15px; } } } }
	&--rside {
		li {
			a {
				&::before {
					content: "";
					margin-right: 15px; } } } } }

/*==============================================================
						COMMON STYLE START */
/*============================================================= */
.main {
	padding-top: 88px;
	min-height: calc(100vh - 220px);
	overflow-x: hidden;
	@include sm(padding-top, 80px); }

/*============= MODIFICATORS ============= */
.--lblue {
	--background: #F1F6F8;
	background: var(--background); }
.--lpink {
	--background: #FEF5FB;
	background: var(--background); }
.--dpink {
	--background: rgba(255, 28, 171, 0.08);
	background: var(--background); }
.--lpurple {
	--background: rgba(245, 245, 254, 1);
	background: var(--background); }
.--pleft {
	clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 calc(100% - 50px));
	padding: 50px 0 130px 0;
	@include sm-block() {
		clip-path: polygon(0 0, 100% 35px, 100% 100%, 0 calc(100% - 35px));
		padding: 35px 0 60px 0; } }
.--pright {
	clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
	padding: 50px 0 100px 0;
	@include sm-block() {
		clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
		padding: 35px 0 60px 0; } }

/*============= Slick slider ============= */
.slick-slider {
	position: relative;
 }	//padding: 0 25px
//.slick-slide
///margin: 0 10px

.slider__inner {
	position: relative;
	.slick-dots {
		display: flex;
		position: absolute;
		top: 95%;
		left: 50%;
		transform: translate(-50%, -50%);
		li {
			padding: 5px;
			cursor: pointer;
			button {
				font-size: 0;
				display: block;
				width: 10px;
				height: 10px;
				border: 2px solid #fff;
				background-color: #fff;
				transition: 0.3s all; }
			&.slick-active {
				button {
					transform: scale(1);
					background: var(--main-color); } } } }
	.slick-arrow {
		position: absolute;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		font-size: 0;
		width: 40px;
		height: 100%;
		transform: scaleX(0.8);
		&::before {
			content: "";
			border: solid #ffffff;
			border-width: 0 3px 3px 0;
			display: inline-block;
			padding: 10px;
			transition: all 0.3s ease 0s; }
		&:hover {
			&::before {
				border: solid var(--main-color);
				border-width: 0 3px 3px 0; } } }

	.slick-prev {
		left: 5%;
		transform-origin: left;
		&::before {
			transform: rotate(135deg);
			margin-right: -10px; } }
	.slick-next {
		right: 5%;
		transform-origin: right;
		&::before {
			transform: rotate(-45deg);
			margin-left: -10px; } } }

.products__slider {
	position: relative;
	.slick-slide {
		padding: 0 15px;
		@include sm-block() {
			padding: 0 5px; }
		@include xs-block() {
			padding: 0; } }
	.slick-list {
		@include xs-block() {
			padding-left: 0!important; } }
	.slick-arrow {
		position: absolute;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		font-size: 0;
		width: 40px;
		height: 100%;
		transform: scaleX(0.8);
		&::before {
			content: "";
			border: solid #646464;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 10px;
			transition: all 0.3s ease 0s; }
		&:hover {
			&::before {
				border: solid var(--main-color);
				border-width: 0 2px 2px 0; } } }

	.slick-prev {
		left: 0;
		transform-origin: left;
		&::before {
			transform: rotate(135deg);
			margin-right: -10px; } }
	.slick-next {
		right: 0;
		transform-origin: right;
		&::before {
			transform: rotate(-45deg);
			margin-left: -10px; } } }

.comp6 {
	// .slick-dotted
	// 	padding-top: 0
	// 	padding-bottom: 25px
	// .slick-dots
	// 	top: initial
 }	// 	bottom: 0
.comp1 {
	// .slick-slider
	// 	padding: 0
	// .slick-dotted
	// 	padding-top: 0
	// 	padding-bottom: 25px
	// .slick-dots
	// 	top: initial
 }	// 	bottom: 0

.comp3 {
	.slick-arrow {
		height: 50%; } }
//.slick-dotted
//	padding-top: 30px
.slick-dots {
	position: absolute;
	top: 0;
	display: flex;
	flex-wrap: wrap;
	li {
		padding: 5px;
		cursor: pointer;
		button {
			font-size: 0;
			display: block;
			width: 6px;
			height: 6px;
			border: 1px solid transparent;
			background-color: #C154C1;
			border-radius: 50%;
			transition: 0.3s all; }
		&.slick-active {
			button {
				border-color: var(--dpink);
				transform: scale(2);
				background: #fff; } } } }

.slick-arrow {
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	font-size: 0;
	width: 50px;
	height: 100%;
	transform: scaleX(0.6);
	&::before {
		content: "";
		border: solid black;
		border-width: 0 1px 1px 0;
		display: inline-block;
		padding: 10px; } }
.slick-prev {
	left: 0;
	transform-origin: left;
	&::before {
		transform: rotate(135deg);
		margin-right: -10px; } }
.slick-next {
	right: 0;
	transform-origin: right;
	&::before {
		transform: rotate(-45deg);
		margin-left: -10px; } }


/* SECTIONS SCREEN`S*/
.screen {
	position: relative;
	padding-bottom: 25px;
	overflow: hidden;
	z-index: 2;
	$this: &;
	&--white {
		#{$this}__title {
			color: #fff; }
		@include sm-block() {
			.navigation li a {
				color: #fff; } } }
	&--wedding {
		#{$this}__inner::after {
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0)); }
		#{$this}__scroll-down i {
			background-image: url('../img/elements/arrow--down-pink.svg'); }
		#{$this}__border {
			background: #FF1CAB; } }
	&--events {
		#{$this}__title {
			letter-spacing: 2vw; } }
	&--eschool {
		#{$this}__inner {
			background-position-x: 59%; }
		#{$this}__title {
			letter-spacing: 3vw; }
		#{$this}__nav::after {
			background-image: linear-gradient(-110deg, rgba(228, 185, 185, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
			width: 64%; } }
	&--kids {
		#{$this}__inner {
			background-position-x: 20%; }
		#{$this}__nav::after {
			width: 60%;
			background-image: linear-gradient(-110deg, rgba(241, 239, 224, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%); }
		@include sm-block() {
			#{$this}__title {
				color: #fff; }
			.navigation li a {
				color: #fff; } } }
	&--party {
		#{$this}__inner::after {
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0)); }
		#{$this}__nav::after {
			background-image: linear-gradient(-110deg, rgba(188, 198, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%); } }
	&--eshow {
		#{$this}__inner::after {
 }			// background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 16.99%, #240505 100%)
		#{$this}__nav::after {
			background-image: linear-gradient(-110deg, rgba(188, 198, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
			width: 60%; } }
	&--product {
		#{$this}__nav::after {
			background-image: linear-gradient(-110deg, rgba(188, 198, 236, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
			width: 50%; } }
	&--blog {
		#{$this}__nav::after {
			background-image: linear-gradient(-110deg, rgba(241, 239, 224, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
			width: 60%; } }
	&--contacts {
		#{$this}__subtitle {
			display: none;
			bottom: 45%;
			a {
				color: #fff; } }
		@include sm-block() {
			#{$this}__title {
				color: #fff; }
			.navigation li a {
				color: #fff; }
			#{$this}__subtitle {
				display: block; } } }

	&__inner {
		min-height: 950px;
		background: no-repeat center top/cover;
		clip-path: polygon(100% 0, 100% 94%, 50% 100%, 0 94%, 0 0);
		@include sm-block() {
			background-attachment: fixed;
			min-height: calc(100vh - 100px); //header height + pbottom + lines
			padding-bottom: 50px;
			&::after {
				content: "";
				background-image: linear-gradient(to top, var(--main-color) 10%, rgba(255, 255, 255, 0));
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 100%;
				z-index: -1; } } }

	&__content {
		display: flex;
		justify-content: flex-end;
		min-height: inherit;
		@include sm-block() {
			flex-direction: column; } }
	&__title {
		position: absolute;
		text-align: center;
		bottom: 40%;
		font-weight: 300;
		font-size: 72px;
		letter-spacing: 3.5vw;
		text-indent: 3.5vw;
		text-align: center;
		left: 0;
		right: 0;
		color: rgb(66, 35, 98);
		z-index: 2;
		text-transform: uppercase;
		user-select: none;
		@include sm-block() {
			bottom: 55%;
			font-size: 32px;
			font-weight: normal; } }
	&__subtitle {
		position: absolute;
		text-align: center;
		bottom: 35%;
		width: 100%;
		left: 0;
		right: 0;
		font-weight: 300;
		font-size: 24px; }
	&__nav {
		width: 390px;
		padding-top: 55px;
		text-align: right;
		z-index: 1;
		@include sm-block() {
			text-align: center;
			padding-top: 50%;
			width: 100%;
			.social {
				display: none; }
			.navigation li a::after {
				display: none; } }
		&::after {
			content: "";
			position: absolute;
			background-image: linear-gradient(-110deg, rgba(241, 224, 238, 0.7) 50%, rgba(255, 255, 255, 0) 50.1%);
			width: 45%;
			height: 100%;
			top: 0;
			right: 0;
			z-index: -1;
			@include sm(display, none); } }
	&__scroll-down {
		display: none;
		position: absolute;
		bottom: 50px;
		left: calc(50% - 8px);
		width: 16px;
		height: 20px;
		cursor: pointer;
		i {
			background: url('../img/elements/arrow--down-white.svg') no-repeat center;
			width: 100%;
			height: 6px;
			margin-top: -2px;
			display: block;
			animation: arrowDown 2s infinite;
			&:nth-of-type(1) {
				opacity: 0.2; }
			&:nth-of-type(2) {
				opacity: 0.45;
				animation-delay: 0.2s; }
			&:nth-of-type(3) {
				animation-delay: 0.4s; } }
		@include sm(display, block); }
	&__border {
		background: #0E17F5;
		&::before,
		&::after {
			content: "";
			width: 100%;
			height: calc(100% - 20px);
			clip-path: polygon(100% 0, 100% 94%, 50% 100%, 0 94%, 0 0);
			background: #fff;
			display: block;
			position: absolute;
			z-index: -1;
			bottom: 20px; }
		&::before {
			background: inherit;
			bottom: 19px; }
		i {
			position: absolute;
			left: calc(50% - 4px);
			width: 9px;
			height: 9px;
			background: inherit;
			outline: 2px solid #fff;
			transform: rotate(45deg); } } }


/*============= HOME - SECTION 1 ============= */
.base {
	background: #F1F6F8;
	position: relative;
	padding-bottom: 25px;
	z-index: 2;
	clip-path: polygon(100% 0, 100% 100%, 50% calc(100% - 15px), 0 100%, 0 0);
	@include sm-block() {
		background: none;
		clip-path: none;
		.screen__border {
			&::before,
			&::after {
				clip-path: polygon(100% 0, 100% calc(100% - 70px), 50% 100%, 0 calc(100% - 70px), 0 0); } }

		.screen__scroll-down {
			bottom: 40px; } }
	&__inner {
		min-height: 1000px;
		display: flex;
		clip-path: polygon(100% 0, 100% 94%, 50% 100%, 0 94%, 0 0);
		@include md(min-height, 800px);
		@include sm-block() {
			flex-direction: column;
			height: calc(100vh - 40px); //40 - header
			min-height: 700px; } }
	&__part {
		$mask-height: 115;
		display: flex;
		position: relative;
		background: no-repeat center top/cover;
		min-height: 100%;
		flex: 0 0 50%;
		@include sm-block() {
			min-height: auto;
			clip-path: polygon(100% 0, 100% calc(100% - 70px), 50% 100%, 0 calc(100% - 70px), 0 0);
			padding-bottom: 0;
			text-align: right; }
		&--wedding {
			background-image: url('../img/base__wedding.jpg');
			justify-content: flex-end;
			border-right: 2px solid #fff;
			.base__title {
				right: 0;
				letter-spacing: 0.7em;
				@include sm-block() {
					letter-spacing: 9vw;
					text-indent: 9vw; } } }
		&--event {
			background-image: url('../img/base__events.jpg');
			border-left: 2px solid #fff;
			@include sm-block() {
				justify-content: flex-end;
				clip-path: polygon(50% 70px, 100% 0, 100% calc(100% - 70px), 50% 100%, 0% calc(100% - 70px), 0 0);
				margin-top: -60px;
				flex-basis: calc(50% + 60px);
				border-left: none; }
			.base__title {
				left: 70px;
				color: #fff;
				letter-spacing: 0.08em;
				@include sm-block() {
					letter-spacing: 4vw;
					text-indent: 4vw;
					bottom: 20%; } }
			&::after {
				transform: scale(-1, 1); } } }
	&__title {
		position: absolute;
		bottom: 35%;
		font-weight: 300;
		font-size: 72px;
		color: #422362;
		z-index: 2;
		text-transform: uppercase;
		user-select: none;
		@include xxl(font-size, 60px);
		@include lg(font-size, 50px);
		@include md(font-size, 40px);
		@include sm-block() {
			font-size: 24px;
			font-weight: normal;
			left: 0 !important;
			right: 0 !important;
			text-align: center; } }
	&__nav {
		position: relative;
		height: 100%;
		width: 390px;
		padding-top: 55px;
		z-index: 1;
		@include sm-block() {
			width: 66.6%;
			padding-right: 10px;
			padding-top: 20px;
			.social {
				display: none; }
			.navigation {
				margin-top: 0; } }
		&--lside {
			padding-right: 65px;
			text-align: right;
			background-image: linear-gradient(to left bottom, rgba(241, 224, 238, 0.7) 50%,rgba(255,255,255,0) 50.1%);
			@include sm(background-image, linear-gradient(-110deg, rgba(241, 224, 238, 0.7) 50%,rgba(255,255,255,0) 50.1%) !important); }
		&--rside {
			padding-left: 65px;
			background-image: linear-gradient(to right bottom, rgba(188, 204, 236, 0.7) 50%,rgba(255,255,255,0) 50.1%);
			@include sm(background-image, linear-gradient(-110deg, rgba(188, 204, 236, 0.7) 50%,rgba(255,255,255,0) 50.1%) !important);
			@include sm(padding-left, 0);
			@include sm-block() {
				padding-top: 50px;
				li {
					a {
						display: inline-flex;
						flex-direction: row-reverse;
						align-items: center;
						&::before {
							margin-right: 0;
							margin-left: 15px; } } } } } } }


/*============= HOME - SECTION 2 ============= */
.advant {
	position: relative;
	@include sm-block() {
			&::after {
				// content: ""
				width: 100%;
				height: 60%;
				position: absolute;
				z-index: -1;
				background: #F1F6F8;
				top: -100px;
				clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%); } }
	&__list {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		@include md(justify-content, space-around); }
	&__item {
		flex: 0 1 33.3%;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		margin-bottom: 60px;
		&:hover {
			.advant__ico {
				filter: contrast(1); } } }
	&__ico {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		width: 100px;
		height: 100px;
		margin-bottom: 20px;
		filter: contrast(0);
		transition: 0.3s filter; }
	&__txt {
		font-size: 14px;
		color: #000;
		max-width: 300px;
		margin: 0 auto;
		margin-bottom: 30px; }
	&__btn {
		margin-top: auto; } }
/*============= HOME - SECTION 3 ============= */
.our-team {
	@include sm-block() {
		clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
		padding-top: 50px;
		padding-bottom: 90px; } }

/*============= HOME - SECTION 4 ============= */
.owner {
	position: relative;
	padding-bottom: 80px;
	margin-top: -50px;
	@include sm(padding-bottom, 30px);
	&__title {
		margin-bottom: 15px; }
	&__photo {
		height: 650px;
		clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
		@include sm-block() {
			height: 360px;
			clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%); }
		img {
			width: 100%;
			height: 100%;
			object-fit: cover; } }
	&__info {
		margin-top: 60px; }
	&__caption {
		font-size: 24px;
		font-weight: normal;
		text-align: center;
		color: #525252;
		margin-bottom: 15px; }
	&__desc {}

	&__contacts {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;
		.social {
			margin: 25px 0;
			li {
				width: 30px;
				height: 30px; } } } }

/*============= WEDDING START ============= */
.organize {
	// margin-top: -80px
	// padding-top: 80px
	padding: 0 25px 60px 25px;
	&__list {
		display: flex;
		flex-flow: row wrap;
		justify-content: center; }
	&__item {
		flex: 0 1 calc(100%/4 - 20px);
		margin: 10px;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		@include md-block {
			flex-basis: calc(100%/2 - 10px);
			margin: 0; }
		@include xs(flex-basis, 100%);
		&:hover {
			.organize__ico {
				filter: contrast(1); } } }
	&__ico {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		width: 100px;
		height: 100px;
		margin-bottom: 20px;
		filter: contrast(0);
		transition: 0.3s filter; }
	&__txt {
		// font-size: 18px
		color: #525252;
		max-width: 300px;
		margin: 0 auto;
		@include sm(font-size, 14px);
		p {
			margin-bottom: 35px; } } }
.wedding {
	&__care {
		margin-bottom: 50px; }
	&__price {
		margin-top: 50px; }
	&__school {
		margin-top: -50px;
		.comp5 {
			&__inner {
				padding: 0 25px; } } }
	&__service {
		margin-top: 80px;
		margin-bottom: 40px;
		@include sm(margin-bottom, -20px); } }
.portfolio {
	clip-path: polygon(0 50px, 100% 0, 100% calc(100% - 50px), 0 100%);
	padding: 50px 0 130px 0;
	@include sm(padding, 20px 0 60px 0);
	.slick-arrow {
		top: initial;
		bottom: 0;
		align-items: flex-end;
 }		// height: 80px
	&__inner {
		text-align: center; }
	&__list {
		position: relative;
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		margin-bottom: 50px; }
	&__item {
		max-width: 300px;
		margin: 10px; }
	&__gallery {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 4px;
		figure {
			overflow: hidden;
			&:nth-child(5) {
            grid-row: span 2;
            grid-column: span 2; }
			img {
				width: 100%;
				height: 100%;
				object-fit: cover; } } }
	&__gallery-name {
		color: var(--main-color);
		text-align: center;
		padding-top: 10px;
		p {
			font-size: 24px;
			line-height: 1.5; }
		small {
			font-size: 16px; } } }
