.page-404 {
    background: url('../img/404.jpg') no-repeat 50% 0/cover;
    min-height: calc(100vh - 55px);
    @include sm(min-height, calc(100vh - 40px));
    &__inner {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        text-align: center; }
    &__title {
        color: var(--main-color);
        line-height: 2;
        font-size: 96px;
        font-weight: 300;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @include sm-block() {
            font-size: 72px;
            line-height: 1.8; } }
    &__subtitle {
        color: var(--main-color);
        margin-bottom: 45px;
        @include sm(margin-bottom, 20px);
        h3 {
            font-size: 24px;
            font-weight: normal;
            line-height: 1.8;
            margin: 10px;
            @include sm(font-size, 18px); }
        h4 {
            font-weight: 400;
            @include sm(font-size, 14px); } }
    &__btn {
        width: 250px;
        text-align: center; } }

