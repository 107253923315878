/*============= FEEDBACK SECTION ============= */
.feedback {
    // +sm(background, none)
    // +sm(clip-path, none)
    &__title {
        margin-bottom: 50px; }
    &__form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 25px;
        align-items: end;
        @include xs(grid-template-columns, 1fr); }
    &__label {
        display: block;
        color: #525252;
        &--textarea {
            grid-column: 1/-1; }
        &--error {
            color: red;
            .feedback__bar {
                background: red;
                &::before, &::after {
                    width: 50%;
                    background: red; } } } }
    &__field {
        &:focus ~ .feedback__bar::before,
        &:focus ~ .feedback__bar::after {
            width: 50%; } }
    &__highlight {
        position: relative;
        user-select: none; }
    &__bar {
        position: relative;
        display: block;
        background: var(--lblue);
        width: 100%;
        height: 1px;
        &::before, &::after {
            content: '';
            height: 2px;
            width: 0;
            bottom: 0;
            position: absolute;
            background: var(--main-color);
            transition: 0.2s width ease; }
        &::before {
            left: 50%; }
        &::after {
            right: 50%; } }
    &__btn {
        grid-column: 1/-1;
        justify-self: center;
        @include sm(margin-top, 25px); } }
