 :root {
	--main-color: #A30660;
	--lblue: #CFDDE2;
	--secondary-color: #CA91B2;
	--main-font: 'Montserrat', sans-serif;
	--background: #fff; }
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	&:before,
	&:after {
		box-sizing: border-box; } }
 :focus {
	outline: none; }

 ::placeholder {
	opacity: 1;
	color: #000;
	transition: 0.4s transform, 0.4s opacity; }

 ::-moz-selection {
	background: var(--main-color);
	color: #fff; }

 ::selection {
	background: var(--main-color);
	color: #fff; }

 ::-webkit-scrollbar {
	width: 6px;
	height: 6px; }

 ::-webkit-scrollbar-thumb {
	background-color: var(--main-color);
	border-radius: 3px; }


html, body {
	height: 100%; }

body {
	position: relative;
	min-width: 320px;
	max-width: 1920px;
	margin: 0 auto;
	font-size: 16px;
	line-height: 1.65;
	color: #000000;
	font-family: var(--main-font);
	font-weight: 400;
	overflow-x: hidden;
	@include sm(font-size, 14px);
	// &::before
	// 	content: ""
	// 	position: fixed
	// 	z-index: 222
	// 	left: 50%
	// 	height: 100%
	// 	width: 1px
 }	// 	background: red

a, button {
	background: transparent;
	text-decoration: none;
	cursor: pointer;
	color: var(--main-color);
	outline: none;
	border: none; }
img {
	max-width: 100%;
	display: block;
	height: auto; }

strong, b {
	color: #000;
	font-weight: 500; }

ul {
	list-style: none; }

input,
textarea,
select {
	background: transparent;
	display: block;
	padding: 5px;
	font-size: 16px;
	font-family: var(--main-font);
	font-weight: 400;
	line-height: normal;
	border: none;
	outline: none;
	color: #ffffff;
	&::placeholder {
		color: var(--lblue);
		opacity: 1; } }
textarea {
	resize: vertical;
	max-height: 200px;
	min-height: 32px;
	height: 32px; }

input[type='radio'],
input[type='checkbox'],
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	cursor: pointer; }

select {
	background: url('../img/icons/select-arrow-icon.svg') no-repeat right 10px center/15px; }

input {
	&:focus::placeholder {
		transform: translateX(9px);
		opacity: 0; }

	&[type='checkbox'],
	&[type='radio'] {
		background: #fff;
		position: relative;
		width: 16px;
		height: 16px;
		border: none;
		padding: 0;
		cursor: pointer;
		width: 18px;
		height: 18px;
		border: 2px solid #000;
		transition: 0.2s; }

	&[type='checkbox'] {
		//border-radius: 2px
		border: 1px solid #979797;
		width: 20px;
		height: 20px;
		&::before {
			content: "";
			position: absolute;
			top: 2px;
			left: 6px;
			width: 6px;
			height: 12px;
			border: 2px solid #fff;
			border-top-width: 0;
			border-left-width: 0;
			transform: rotate(45deg) scale(0);
			transition: 0.2s; }
		&:checked {
			background-color: var(--main-color);
			border-color: var(--main-color);
			&::before {
				transform: rotate(45deg) scale(1); } } }

	&[type='radio'] {
		border-radius: 50%;
		&::before {
			content: "";
			background-color: var(--main-color);
			position: absolute;
			top: 3px;
			left: 3px;
			border: none;
			width: 8px;
			height: 8px;
			border-radius: inherit;
			transform: scale(0);
			transition: 0.2s; }
		&:checked {
			border-color: var(--main-color);
			background-color: #fff;
			&::before {
				transform: scale(1); } } } }

.invisible {
	visibility: hidden;
	opacity: 0; }
.hidden {
	overflow: hidden;
	height: 100dvh; }

.container {
	width: 100%;
	max-width: 1246px;
	padding-right: 15px;
	padding-left: 15px;
	margin: 0 auto;
	//border-left: 1px solid red
 }	//border-right: 1px solid red


.progress-container {
	margin-top: 30px;
	height: 4px;
	width: 100%;
	background: #EFE8E8;
	display: none;
	@include md(display, block); }


.progress-bar {
	height: 100%;
	width: 0;
	background-color: var(--main-color);
	transition: width 0.3s ease; }
