/*==============================================================
						HEADER START */
/*============================================================= */
.header {
	background-color: #fff;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	height: 88px;
	z-index: 3;
	@include sm-block() {
		height: 80px; }
	&__inner {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 15px;
			border: 1px solid #646464; } }
	&__select {
		background-color: var(--main-color);
		width: 60px;
		height: 32px;
		font-weight: 500;
		border-radius: 4px;
		margin-left: 16px;
		@include md(display, none); }
	&__lside {
		flex: 1;
		@include md(display, none); }
	&__rside {
		flex: 1;
		@include md(display, none);
		.header__nav {
			justify-content: flex-end; } }
	&__logo {
		flex-shrink: 0;
		width: 280px;
		height: 66px;
		@include md(display, none);
		a {
			display: block;
			img {
				display: block; } } }
	&__nav {
		display: flex;
		justify-content: right;
		> li {
			> a {
				display: inline-flex;
				align-items: center;
				padding: 15px 16px;
				color: #646464;
				font-size: 16px;
				line-height: 24px;
				font-weight: 500;
				span {
					transition: 0.3s border;
					border-bottom: 1px solid transparent; } }
			.current {
				color: var(--main-color);
				span {
					border-color: var(--main-color); } }
			&:hover {
				a {
					color: var(--main-color); }
				span {
					border-color: var(--main-color); } } } } }
.hidden .main {
	position: relative;
	filter: blur(3px);
	opacity: 0.3;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1; } }


.hidden .footer {
	position: relative;
	filter: blur(3px);
	opacity: 0.3;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1; } }
.mobile {
	&__header {
		display: none;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		color: #DCCADC;
		font-size: 12px;
		font-weight: 300;
		transition: 0.5s;
		@include md-block() {
			display: flex; } }
	&__logo {
		@include sm-block() {
			max-width: 200px; } }
	&__items {
		display: flex; }
	&__select {
		background-color: var(--main-color);
		width: 60px;
		height: 32px;
		font-weight: 500;
		border-radius: 4px;
		margin-left: 16px;
		@include md(display, flex); }
	&__menu {
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
		&-burg {
			position: relative;
			width: 32px;
			height: 32px;
			background-color: transparent;
			border: 0;
			cursor: pointer;
			font-size: 0;
			margin-left: 24px;
			transition: 0.2s linear;
			z-index: 100;
			i {
				top: 50%;
				display: block;
				position: absolute;
				width: inherit;
				height: 3px;
				transition: 0.2s transform;
				background-color: var(--main-color);
				&:after,
				&:before {
					content: "";
					position: absolute;
					left: 0;
					width: inherit;
					height: inherit;
					background-color: var(--main-color);
					transition: transform ease 0.15s; }

				&:before {
					top: -8px; }
				&:after {
					bottom: -8px; } } }
		&.active {
			.mobile__menu-burg i {
				background: none;
				&:before {
					transform: rotate(45deg);
					top: 0;
					background-color: #ffffff; }
				&:after {
					transform: rotate(-45deg);
					bottom: 0;
					background-color: #ffffff; } } } }
	&__nav {
		z-index: 1;
		visibility: hidden;
		position: fixed;
		width: 40%;
		height: 100%;
		right: -100%;
		top: 0;
		background: var(--main-color);
		padding: 80px 10px 20px 10px;
		transition: all 0.3s ease 0s;
		overflow: auto;
		//animation: fadeIn 0.3s both
		&.active {
			visibility: visible;
			right: 0; }
		@include xs(width, 60%); }
	&__navcity {
		font-size: 18px;
		letter-spacing: 2rem;
		text-indent: 2rem;
		font-weight: 300;
		text-transform: uppercase;
		text-align: center; }
	&__navlist {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 15px;
		> li {
			> a {
				display: block;
				padding: 8px 0;
				color: #fff;
				font-size: 18px;
				span {
					transition: 0.3s border;
					border-bottom: 1px solid transparent; } }
			.current {
				color: #EFE8E8;
				span {
					border-color: #EFE8E8; } } } } }

