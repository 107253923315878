.about-us {
  margin-top: 56px;
  margin-bottom: 100px;

  @include md(margin-top, 40px);
  @include xs(margin-top, 24px);
  @include md(margin-bottom, 80px);

  &__inner {
    display: grid;
    grid-template-columns: 2fr 3fr;
    @include md-block() {
      grid-template-columns: 1fr;
      gap: 20px; } }
  &__info {
    p {
      line-height: 24px;
      &:nth-of-type(1) {
        margin-bottom: 10px; }
      &:nth-of-type(2) {
        margin-bottom: 10px; } } } }

.why-us {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);

  &__inner {
    display: grid;
    grid-template-columns: 2fr 3fr;
    @include md-block() {
      grid-template-columns: 1fr;
      gap: 20px; } }
  &__info {
    p {
      line-height: 24px;
      &:nth-of-type(1) {
        margin-bottom: 10px; }
      &:nth-of-type(2) {
        margin-bottom: 10px; } } } }

.about-image {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);

  &__block {
    height: fit-content;
    & img {
      width: 100%;
      object-fit: cover;
      height: 440px;
      @include md(height, 320px); } } }

.partners {
  margin-bottom: 100px;
  @include md(margin-bottom, 80px);

  &__inner {
    display: grid;
    grid-template-columns: 2fr 3fr;
    //gap: 100px
    @include md-block() {
      grid-template-columns: 1fr;
      gap: 20px; } }
  &__title {
    max-width: 384px; }

  &__info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
    @include md(gap, 20px);
    @include xs-block() {
      grid-template-columns: repeat(2, 1fr); } } }
