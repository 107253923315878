.standard {
  margin-top: 56px;
  margin-bottom: 100px;
  @include md-block() {
    margin-bottom: 80px;
    margin-top: 40px; }
  &__inner {}
  &__title {
    display: flex;
    align-items: center;
    > button {
      display: none;
      @include xs-block() {
        display: flex;
        max-width: 100%; } }
    @include sm-block() {
      flex-direction: column;
      align-items: normal;
      gap: 24px; }
    h2 {
      font-size: 32px;
      line-height: 40px;
      color: var(--main-color);
      font-weight: 600;
      text-transform: uppercase;
      @include md-block() {
        font-size: 24px;
        line-height: 32px; }
      @include sm-block() {
        text-align: center; } }
    &__btn {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      button {
        max-width: 100px;
        background: #ffffff;
        border: 1px solid var(--main-color);
        color: #646464;
        @include sm-block() {
          max-width: 100%; } }
      .active {
        background: var(--main-color);
        color: #ffffff; } } } }
