.gallery {
    margin-bottom: -75px;
    @include sm(margin-bottom, -35px);
    &__inner {
        padding-top: 70px;
        padding-bottom: 180px;
        @include sm-block() {
            padding-bottom: 70px;
            padding-top: 20px; } }
    &__title {
        color: var(--main-color);
        line-height: 1.8;
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        &--page {
            font-weight: 500;
            margin-bottom: 30px; } }
    &__ico {
        margin-top: 5px;
        margin-bottom: 30px;
        img {
            max-width: 100%;
            display: block;
            height: auto;
            margin: 0 auto; } }
    &__filter {
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0;
        margin-left: -15px;
        @include sm-block() {
            flex-wrap: nowrap;
            overflow-x: auto;
            &::-webkit-scrollbar-thumb:horizontal {
                display: none; } }
        li {
            span {
                display: block;
                padding: 10px 15px;
                font-weight: 300;
                line-height: 1.4;
                cursor: pointer;
                user-select: none;
                white-space: nowrap;
                transition: 0.4s color;
                &:hover {
                    color: var(--main-color); } }
            &.active {
                span {
                    color: var(--main-color); } } } }
    &__articles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px 20px;
        margin-top: 32px;
        @include sm-block() {
            grid-template-columns: 1fr;
            margin-top: 0; } }
    &__article {
        animation: fadeIn 0.5s both;
        &:hover {
            img {} } }
    &__img {
        width: 100%;
        height: 290px;
        clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
        overflow: hidden;
        @include xs(height, 112px);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s transform;
            will-change: transform; } }
    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px; }
    &__count {
        // font-size: 18px
        line-height: 1.5;
        color: var(--main-color); }
    &__name {
        color: var(--main-color);
        font-size: 24px;
        line-height: 1.5;
        text-align: center;
        flex-shrink: 1;
        @include sm(font-size, 14px); }
    &__price {
        color: #FF1CAB;
        flex-wrap: nowrap;
        small {
            font-size: 10px; } } }


/* Gallery photos */
.gallery {
    &__photos {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        @include md(grid-auto-rows, 200px);
        @include sm-block() {
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 29vw; } }
    &__photo {
        overflow: hidden;
        &:nth-child(10n + 7),
        &:nth-child(10n + 8) {
            grid-row: span 2;
            grid-column: span 2; }
        > * {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s transform; }
        &:hover > * {
            transform: scale(1.05); }
        @include sm-block() {
            grid-row: auto !important;
            grid-column: auto !important;
            &:nth-child(12n + 4),
            &:nth-child(12n + 11) {
                grid-row: span 2 !important;
                grid-column: span 2 !important; } } } }
/* Gallery page inner */
.gallery-page {
    @include sm-block() {
        // background-color:  #F5F5FE
        &::after {
            content: "";
            position: absolute;
            clip-path: polygon(0 40%, 100% 0%, 100% 40%, 0 75%);
            top: 30px;
            width: 100%;
            height: 100%;
            background-color:  #F5F5FE;
            z-index: -1; } }
    &__inner {
        padding-top: 70px;
        padding-bottom: 210px;
        @include sm-block() {
            padding-bottom: 70px;
            padding-top: 20px; } }
    &__title {
 }        // margin-bottom: 25px
    &__ico {
        margin-top: 15px;
        margin-bottom: 32px;
        img {
            max-width: 100%;
            display: block;
            height: auto;
            margin: 0 auto; } }
    &__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 300px;
        grid-gap: 40px 30px;
        margin-bottom: 40px;
        @include sm-block() {
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
            grid-row-gap: 25px;
            margin-bottom: 10px; } }
    &__photo {
        grid-row: 1/3;
        height: 640px;
        width: 100%;
        clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
        overflow: hidden;
        @include sm(height, 290px);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__txt {
        line-height: 1.8;
        overflow: hidden;
        h4 {
            color: #525252;
            font-weight: bold; }
        p {
            font-weight: 300;
            margin-bottom: 20px; } }
    &__vid {
        height: 300px;
        width: 100%;
        clip-path: polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px);
        overflow: hidden;
        @include sm(display, none);
        > * {
            height: 100%;
            width: 100%;
            object-fit: cover; } }
    &__btn {
        text-align: center;
        margin-top: 55px;
        margin-bottom: 50px; } }
