/*============= EVENTS__PARTY PAGE ============= */
.events {
	&__conference {
		margin-top: 65px;
		margin-bottom: 80px;
		@include sm-block() {
			margin-top: -30px;
			margin-bottom: 0px; } }
	&__ceremony {
		@include sm(margin, 50px 0); }
	&__show-center {
		margin-bottom: -135px;
		@include sm(margin-bottom, -50px); } }
/*============= EVENTS__SHOW PAGE START============= */
.fashion-animals {
	position: relative;
	margin-bottom: -50px;
	// &::before
	// 	content: ""
	// 	clip-path: polygon(59% 0, 100% 0, 100% 100%, 0 100%, 0 73%)
	// 	background: #F5F5FE
	// 	width: 100%
	// 	height: 100%
	// 	display: block
	// 	position: absolute
	// 	z-index: -1
	// 	top: -55px
	// 	+sm(content, none)
	&__subtitle {
		max-width: 620px;
		margin: 0 auto;
		margin-bottom: 25px;
		padding: 15px;
		text-align: center; }
	&__btn {
		padding-top: 60px;
		// padding-bottom: 140px
		text-align: center;
		@include sm(padding-bottom, 30px); } }
.party__crazy {
	margin-bottom: 95px;
	margin-top: 95px;
	.comp7__bg {
		@include sm-block() {
			clip-path: polygon(50% 8%, 100% 0, 100% 92%, 50% 100%, 0 92%, 0 0);
			padding: 80px 0; } } }
.party__styles {
	@include sm(margin-bottom, -10px); }
.eshow {
	position: relative;
	margin-top: -100px;
	margin-bottom: -320px;
	padding-top: 100px;
	@include sm-block() {
		clip-path: none;
		background: none;
		margin-top: -50px; }
	.comp5__img {
		@include sm(clip-path, polygon(50% 15%, 100% 0, 100% 85%, 50% 100%, 0 85%, 0 0)); }
	.comp8 {
		margin-top: 80px;
		margin-bottom: 20px;
		&__video {
			@include sm(min-height, 240px); } }
	&__santa {
		margin-top: 100px;
		@include sm(margin-top, 200px); } }
.event-top {
	clip-path: polygon(0 0, 100% 0, 100% 69%, 86% 100%, 0 100%);
	margin-top: -90px;
	padding-top: 90px;
	@include sm-block() {
		margin-top: -90px;
		clip-path: none; }
	&__ico {
		img {
			margin: 10px auto; } }
	&__subtitle {
		max-width: 620px;
		margin: 0 auto;
		margin-bottom: 15px;
		padding: 12px;
		text-align: center; }
	&__btn {
		padding: 50px;
		// padding-bottom: 95px
		text-align: center;
		@include sm-block() {
			display: flex;
			flex-flow: column wrap;
			flex-basis: 50%;
			align-items: center;
			padding: 40px 0 30px 0; }
		a {
			margin: 5px 40px;
			@include sm(margin, 0 0 20px 0); } }
	&--r {
		margin-top: 0px;
		clip-path: polygon(24% 0, 100% 0, 100% 69%, 86% 100%, 0 100%, 0 49%);
		padding-top: 10px;
		~.event2 {
			margin-bottom: 70px; }
		@include lg-block() {
			margin-top: -50px;
			padding-top: 10px; }
		@include md(clip-path, polygon(23% 0, 100% 0, 100% 72%, 85% 100%, 0 100%, 0 36%));
		@include sm-block() {
			clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
			padding: 25px 0; }
		// +sm-block()
		// 	clip-path: none
		// 	background: none
 }		// 	padding-top: 50px
	&--dev {
		@include sm-block() {
			padding-bottom: 15px;
			clip-path: polygon(0 0, 100% 0, 100% calc(100% - 35px), 0 100%); } } }
.eshow__singing {
	margin-top: -20px;
	margin-bottom: -50px;
	@include sm-block() {
		margin-top: 0;
		margin-bottom: -35px; }
	.comp7__bg {
		@include sm-block () {
			clip-path: polygon(0 35px, 100% 0, 100% calc(100% - 35px), 0 100%);
			padding: 80px 0; } } }
/*============= EVENTS__SHOW PAGE END============= */
/*============= EVENTS__KIDS PAGE START============= */
.event2 {
	position: relative;
	margin-top: 40px;
	margin-bottom: 100px;
	@include sm-block() {
		margin-top: 0px;
		margin-bottom: 120px; }
	&__ico {
		img {
			margin: 10px auto;
			margin-top: 7px; } }
	&__subtitle {
		max-width: 620px;
		margin: 0 auto;
		margin-bottom: 15px;
		padding: 15px;
		text-align: center; }
	&__btn {
		position: absolute;
		bottom: 0;
		right: calc(50% - 125px);
		.btn {
			width: 250px;
			@include lg(width, 300px); }
		@include lg-block() {
			position: static;
			text-align: center;
			margin: 30px 0; } }
	.comp10 {
		@include sm(background, none); } }
.kids {
	&-born {
		margin-top: 50px; }
	&-aniversary {
		margin-top: 50px; } }
/*============= EVENTS__KIDS PAGE END============= */
